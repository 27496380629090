.cursor {
    opacity: 1;
    animation: blink 1.25s infinite;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: rgba($color-blue, .8);
    margin-right: -26px;
    margin-left: 5px;
    margin-top: -4px;
    vertical-align: middle; }

@keyframes blink {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }
