.about-inside-jobopp {
    .img-line-container {
        margin-top: 50px;
        div {
            text-align: center; }
        img {
            //width: 300px
            width: 100%;
            vertical-align: middle;
            transform: rotate(var(--rotation-angle)); } } }

//@media (min-width: $screen-small)

//@media (min-width: $screen-medium)

@media (min-width: $screen-small) {
    .about-inside-jobopp {
        .img-line-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            div {
                display: inline;
                margin-bottom: -40px; } } } }

@media (min-width: $screen-large) {
    #inside-larger-img {
        width: 150%;
        margin-left: -60px; } }
