#homepage-index {
    .timeline {
        padding-bottom: 100px;
        margin-bottom: -70px;
        margin-top: -30px;

        @media (min-width: $screen-small) {
            margin-top: -35px; } }

    // .timeline-wrapper
    //     margin: -20px 0

    .timeline-past {
        display: block; }

    .container {
        position: relative; } }

.homepage-stats {
    .item:nth-child(1) .circle-strong {
        padding-top: 5px;
        padding-right: 6px; }

    .item:nth-child(2) .circle-strong {
        padding-top: 8px;
        padding-right: 4px; }

    .item:nth-child(3) .circle-strong {
        padding-top: 5px;
        padding-right: 2px; } }
