.references-block {
    .item {
        position: relative; }

    .icon-quotes {
        position: absolute;
        right: 10px;
        top: -5px;
        height: 60px; }

    .portrait {
        width: 150px;
        height: 150px;
        margin: 15px auto;
        display: block; } }

.success-stories-intro {
    text-align: center;
    color: $color-blue;
    font-size: 1.5rem;
    margin-bottom: 30px;
    line-height: 2rem; }

#references-carousel {
    margin-top: 20px;

    .reference-wrapper {
        background: $color-blue;
        text-align: center;
        //padding: 0 25px 15px 25px
        padding: 0 50px; }

    .reference-meta.stickers {
        text-align: center;
        margin-bottom: 15px;
        .sticker-primary, .sticker-secondary {
            width: fit-content;
            float: none;
            margin: 0 auto;
            padding: 8px 12px; } }

    .reference-text {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15px;
        p {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            font-style: italic;
            line-height: 1.8;
            margin-top: 5px;
            margin-bottom: 10px;
            word-break: break-word; } }

    .speech-bubble {
        position: relative;
        background: white;
        border-radius: 1em;
        padding: 15px; }

    .flickity-viewport {
        padding-top: 5px;
        background: $color-blue;
        .small-6 {
            margin-bottom: 50px; } }

    .carousel-cell {
        width: 100%!important; }

    .flickity-prev-next-button {
        //top: 50%!important
        &.previous {
            left: 5px!important; }
        &.next {
            right: 5px!important; } } }

// tablet

@media (min-width: $screen-medium) {
    .reference-wrapper {
        //padding-bottom: 0
        .portrait-and-details {
            float: right;
            width: 25%; }

        .reference-text {
            float: left;
            height: 295px;
            padding-bottom: 0;
            line-height: 295px;
            //margin-left: 25px
            //width: calc(100% - 200px)
            //width: 65%
            width: 70%;
            p {
                text-align: left;
                margin-top: 0; } }
        .speech-bubble:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 30px solid transparent;
            border-left-color: white;
            border-right: 0;
            border-top: 0;
            border-left: 60px solid white;
            margin-top: -15px;
            margin-right: -59px; } }

    .reference-wrapper.reference-odd {
        .portrait-and-details {
            float: left; }
        .reference-text {
            float: right;
 }            //margin-right: 25px
        .speech-bubble:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 30px solid transparent;
            border-right-color: white;
            border-left: 0;
            border-top: 0;
            border-right: 60px solid white;
            margin-top: -15px;
            margin-left: -60px; } } }

@media (min-width: $screen-large) {
    .reference-wrapper {
        .reference-text {
            width: 73%; } } }
