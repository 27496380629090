.columns {
    line-height: 1.5;

    @media (min-width: $screen-small) {
        column-count: 2;
        column-gap: 100px;
        column-rule: 1px dotted $color-lightblue; } }

.column {
    break-inside: avoid-column; }
