$professions-selector-min-width: 193px;
$types-selector-min-width: 180px;
$work-modes-selector-min-width: 174px;

#jobs-count-animation {
    color: #5FA6FC;
    font-weight: bold; }

#job-pictures-container {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden; }

.search-reset-link {
    width: 100%;
    z-index: 9;
    text-align: right;
    display: inline-block;
    position: relative;
    font-size: 0.7rem;
    color: #5FA6FC;
    text-decoration: underline; }

.job-highlights {
    text-align: left;
    margin: 25px 0 10px 0;
    font-size: 0.8rem;
    ul {
        display: inline-block;
        text-align: center; }
    li {
        display: inline-block;
        cursor: pointer;
        padding: 7px 10px 5px 10px;
        text-transform: uppercase;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 4px;
        margin-top: 10px;
        a {
            display: block;
            padding: 2px 0;
            color: white; } } }

#homepage-index .job-highlights {
    top: -5px;
    margin: 10px 0;
    padding-left: 0;
    text-align: center; }

.job-selector-lists {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 30px;
    .job-selector-flex-container {
        text-align: left; }
    strong {
        float: left; }
    p {
        color: white; }
    .scrolling-list-container {
        display: inline-block;
        position: relative;
        padding: 35px 15px 20px 15px;
        width: auto;
        height: auto;
        max-height: 250px;
        margin-top: 31px;
        //margin-left: 13%
        line-height: 2em;
        background: white;
        -webkit-box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
        -moz-box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
        box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
        border-radius: 7px;
        &.inline {
            display: inline-block !important; } }
    .close-button {
        position: absolute;
        right: 14px;
        top: 7px;
        width: 22px;
        height: 22px;
        opacity: 0.3;
        &:hover {
            opacity: 1; }
        &:before, &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 23px;
            width: 2px;
            background-color: #333; }
        &:before {
            transform: rotate(45deg); }
        &:after {
            transform: rotate(-45deg); } }
    .job-select-fields-label {
        margin-right: 34px;
        white-space: nowrap; }
    .twobar {
        white-space: nowrap; }

    ul {
        max-height: 200px;
        overflow: auto;
        overflow-x: hidden;
        margin: auto;
        padding-right: 15px;
        scrollbar-color: #706F6F #EDEDED;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 9px; }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 4px; }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1); }
        li {
            position: relative;
            margin-bottom: 15px;
            white-space: nowrap;
            text-align: left;
            label {
                display: inline-block;
                margin-bottom: 0;
                text-align: left;
                text-transform: none;
                color: black;
                font-weight: bold;
                font-size: 0.8rem;
                vertical-align: middle;
                white-space: nowrap;
                cursor: pointer; } }

        input[type=checkbox] {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -border-radius: 2px;
            height: 19px;
            width: 19px;
            padding: 0;
            margin-right: 10px;
            background: #E1E1E1;
            border: none;
            cursor: pointer;
            vertical-align: middle;
            &:checked {
                background: $color-blue;
                margin-right: 10px; }
            &:focus {
                outline: none; }
            &:checked+label:after {
                content: "";
                background-color: transparent;
                /* position the checkbox */
                position: absolute;
                left: 6px;
                top: 5px;
                /* setting the checkbox */
                /* short arm */
                width: 7px;
                border-bottom: 1.5px solid white;
                /* long arm */
                height: 14px;
                border-right: 1.5px solid white;
                /* rotate the mirrored L to make it a checkbox */
                transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg); } } } }

#professions-selector {
    width: 100%;
    min-width: $professions-selector-min-width;
    min-height: 30px;
    float: left;
    text-align: center;
    .selector-container {
        float: right; }
    .scrolling-list-container {
        label {
            width: 92%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; } }
    .twobar {
        margin-left: calc((100vw - #{$professions-selector-min-width}) / 2 - 13px); } }

#types-selector {
    width: 100%;
    min-width: $types-selector-min-width;
    min-height: 30px;
    float: left;
    margin-top: 20px;
    text-align: center;
    .selector-container {
        float: left; }
    .twobar {
        margin-left: calc((100vw - #{$types-selector-min-width}) / 2 - 15px); }
    li:last-of-type {
        margin-bottom: 0; } }

#work-modes-selector {
    width: 100%;
    min-width: $work-modes-selector-min-width;
    min-height: 30px;
    float: left;
    margin-top: 20px;
    text-align: center;
    .selector-container {
        float: left; }
    .twobar {
        margin-left: calc((100vw - #{$work-modes-selector-min-width}) / 2 - 12px); }
    li:last-of-type {
        margin-bottom: 0; } }

.selector-container {
    width: 100%; }

.twobar {
    cursor: pointer;
    position: absolute;
    height: 30px;
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    padding-left: 15px;
    padding-top: 3px;
    font-size: 0.9rem;
    text-align: left;
    background-color: none;
    #selected-prof-count, #selected-types-count, #selected-work-modes-count {
        color: $color-blue;
        font-size: 1rem;
        font-weight: bold; } }

.twobar .bar {
    position: absolute;
    width: 13px;
    height: 1.5px;
    background: #fff;
    top: 43%;
    right: 11px;
    transition: all 0.2s ease; }

.twobar.arrow-down .bar {
    transform-origin: center;
    height: 2px;
    &:nth-child(1) {
        transform: translateX(-20%) translateY(50%) scale(0.7, 0.7) rotate(55deg); }
    &:nth-child(2) {
        transform: translateX(20%) translateY(50%) scale(0.7, 0.7) rotate(-55deg); } }

.twobar.arrow-up .bar {
    background: $color-yellow;
    &:nth-child(1) {
        transform: translateX(-20%) translateY(50%) scale(0.7, 0.7) rotate(-55deg); }
    &:nth-child(2) {
        transform: translateX(20%) translateY(50%) scale(0.7, 0.7) rotate(55deg); } }

.job-new {
    background: $color-blue;
    color: white;
    font-size: 9px;
    padding: 5px;
    text-transform: uppercase; }

.job-youtube {
    width: 450px;
    margin-left: 30px;
    margin-top: -25px;
    margin-bottom: 15px;
    float: right;
    display: inline-block;
    z-index: 10;
    position: relative;

    @media (max-width: $screen-medium) {
        float: none;
        width: 100%;
        margin: 0;
        margin-bottom: 30px; } }

.job-youtube-wrapper {
    position: relative;
    padding-bottom: 56%;
    padding-top: 5px;
    height: 0;

    iframe {
        background: #000;
        border: 8px solid white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.job-pictures-small {
    margin: 20px 0; }

.job-pictures-large {
    margin-top: 0;
    margin-bottom: 20px; }

#jobs-result-container {
    display: flex;
    flex-wrap: wrap; }

.job-container {
    display: flex;
    padding-bottom: 30px;
    & > a {
        width: 100%; }
    .job-inner-container {
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: #f8f8f8;
        padding: 40px 40px 70px 40px;
        span {
            display: block;
            clear: both;
            font-size: 0.8rem;
            margin-bottom: 30px; }

        .new-job {
            width: 100%;
            position: absolute;
            top: -12px;
            left: 0;
            div {
                padding: 4px 15px;
                font-size: 0.9rem;
                text-transform: none; } }

        .job-detail {
            display: flex;
            text-align: left;
            padding-left: 15%;
            .job-detail-icon {
                float: left;
                width: 15%;
                .material-icons {
                    color: $color-blue;
                    margin-right: 20px; } }
            .job-detail-text {
                float: right;
                width: 85%;
                p {
                    display: table-cell;
                    vertical-align: middle; } } }

        .job-title {
            padding: 10px 0;
            font-size: 1.2rem;
            font-weight: 800;
            color: $color-black;
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey; }

        .job-enterprise-type {
            margin-bottom: 5px; }

        .job-city,
        .job-salary {
            margin-right: 5px;

            i {
                margin: 0;
                width: auto;
                font-size: 19px; } }

        .job-salary {
            font-weight: normal; }

        .job-city strong {
            font-weight: 600; }

        .job-technos,
        .job-advantages,
        .job-created-at,
        .job-status {
            font-size: 12px; }

        .job-type {
            font-size: 0.9rem;
            border-top: 1px solid lightgray;
            padding-top: 25px; }

        .job-view-more {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 35px;
            p {
                width: 180px;
                color: $color-blue;
                border: 2px solid $color-blue;
                border-radius: 12px;
                padding: 12px 25px;
                font-size: 0.85rem;
                font-weight: 700;
                letter-spacing: 0.06rem;
                margin-left: auto;
                margin-right: auto; } } } }

.jobs-list-bottom {
    width: 100%;
    padding: 0 15px;

    .jobs-total {
        float: left;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase; }

    .jobs-pagination {
        float: right; } }

.jobs-noresult {
    text-align: center;
    padding: 40px 50px 60px 50px;
    width: 100%;
    svg {
        width: 80px;
        height: auto;
        margin-bottom: 60px; }
    p {
        font-size: 1.2rem;
        font-weight: 700; } }

.jobs-table {
    border-top: 0;

    thead tr {
        border-top: 0; }

    tr {
        cursor: pointer; }

    .job-title {
        font-weight: 800;
        color: $color-black; }

    .job-city,
    .job-salary {
        margin-right: 5px;

        i {
            margin: 0;
            width: auto;
            font-size: 19px; } }

    .job-salary {
        font-weight: normal; }

    .job-city strong {
        font-weight: 600; }

    .job-technos,
    .job-advantages,
    .job-created-at,
    .job-status {
        font-size: 12px; }

    .job-type {
        font-size: 11px; }

    @media (max-width: $screen-small) {
        .job-technos,
        .job-advantages,
        .job-created-at,
        .job-status {
            font-size: 11px; } } }

.job-meta-list {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;

    li {
        padding: 15px 0 15px 50px;
        position: relative;
        font-size: 13px;

        &:nth-child(odd) {
            background: rgba(#F6F9FC, .8); }

        &:nth-child(even) {
            background: rgba(#fff, .8); } }

    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        vertical-align: middle;
        color: $color-blue;
        font-size: 28px;
        float: left; }

    .job-ref {
        font-weight: 600; }

    .job-contact {
        a {
            font-weight: 600; }
        p.job-contact-email {
            margin-top: 5px;
            font-size: 100%;
            a {
                font-size: 100%;
                font-weight: 100; } } }

    .job-ends-at {
        i {
            color: $color-red !important; } }

    @media (min-width: $screen-small) {
        .job-contact {
            a {
                word-break: keep-all; } }

        .job-ends-at {
            strong {
                display: block; } } } }

.xxl-button {
    width: 100%;
    &.button-fill-primary {
        font-size: 2rem; } }

.jobs-block {
    h4 {
        font-weight: 700;
        text-align: left; }

    p a {
        color: $color-blue; }

    .job-meta-list {
        i {
            font-size: 20px; }

        li {
            font-size: 13px;
            padding-left: 40px; } }

    .row p {
        margin-bottom: 10px;
        font-size: 13px; } }

.job-forums {
    margin-top: 20px;

    .form-actions {
        padding: 0;

        span {
            display: inline-block;
            float: left;
            font-size: 13px;
            padding: 5px;
            color: #888; } } }

@media (min-width: 768px) {
    .search-reset-link {
        width: 70%; }
    .job-highlights {
        width: 70%;
        display: inline-block;
        .scrolling-list-container {
            position: absolute; }
        ul {
            text-align: left;
            margin-left: 22px;
            li {
                margin-left: 10px; }
            label {
                font-size: 1rem; } } }
    .job-selector-lists {
        width: 70%;
        display: inline-block;
        ul li label {
            font-size: 0.9rem; } }
    .selector-container {
        margin-top: -9px; } }

@media (min-width: 768px) and (max-width: 991px) {
    #professions-selector {
        .twobar {
            margin-left: calc((70% - #{$professions-selector-min-width}) / 2 + 3px); } }
    #types-selector {
        .twobar {
            margin-left: calc((70% - #{$types-selector-min-width}) / 2); } }
    #work-modes-selector {
        .twobar {
            margin-left: calc((70% - #{$work-modes-selector-min-width}) / 2 + 3px); } } }

@media (min-width: 992px) and (max-width: 1199px) {
    .job-highlights, .job-selector-lists {
        top: -30px;
        position: relative; }
    .job-highlights {
        ul {
            margin-left: 21px; } }
    .job-selector-flex-container {
        display: flex;
        justify-content: space-between; }
    #professions-selector {
        flex: 1.02 1 0;
        .selector-container {
            display: flex;
            justify-content: flex-start;
            padding-left: 25px; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } }            //margin-left: -54px
    #types-selector {
        flex: 1.02 1 0;
        margin-top: 0;
        .selector-container {
            display: flex;
            justify-content: flex-end; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } }            //margin-left: -89px
    #work-modes-selector {
        flex: 1 1 0;
        margin-top: 0;
        .selector-container {
            display: flex;
            justify-content: flex-end; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } }            //margin-left: -84px
    .selector-container {
        display: flex;
        justify-content: flex-end; } }

@media (min-width: 1200px) {
    .job-highlights, .job-selector-lists {
        padding: 0 5%;
        top: -25px;
        position: relative; }
    .job-highlights {
        ul {
            margin-left: 29px; } }
    .job-selector-flex-container {
        display: flex;
        justify-content: space-between; }
    #professions-selector {
        flex: 1.02 1 0;
        .selector-container {
            display: flex;
            justify-content: flex-start;
            padding-left: 33px; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } }            //margin-left: -92px
    #types-selector {
        flex: 1.02 1 0;
        margin-top: 0;
        .selector-container {
            display: flex;
            justify-content: flex-end; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } }            //margin-left: -89px
    #work-modes-selector {
        flex: 1 1 0;
        margin-top: 0;
        .selector-container {
            display: flex;
            justify-content: flex-end; }
        .twobar {
            margin-left: 0; }
        .scrolling-list-container {
 } } }            //margin-left: -84px
