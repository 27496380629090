.footer {
    padding-top: 100px;
    color: white;
    @include angled-edge('inside top', 'lower right', white);

    .footer-logo svg {
        width: 190px;
        margin: -5px auto 15px;

        #job, #opportun, #it {
            fill: white; } }

    address {
        padding: 0;
        margin: 0;
        line-height: 1.7;
        font-size: 11px;
        margin-bottom: 25px;
        opacity: .8; }

    h4 {
        font-size: 15px;
        margin-bottom: 20px;
 }        //white-space: nowrap

    p, ul {
        line-height: 2;
        font-size: 13px;
        margin-bottom: 15px; }

    ul {
        line-height: 1.7; }

    li {
        margin-bottom: 5px;
        position: relative;
        text-align: center; }

    .col {
        width: 100%;
        padding: 0 15px; }

    .col-3 {
        padding-bottom: 10px;
        span {
            margin-top: 10px; } }

    .col-4 {
        text-align: center; }

    .col-5 {
        text-align: center; }

    .footer-menu-subtitle {
        font-style: italic; }

    .footer-left-border {
        border-left: none;
        padding-left: 0; }

    .item:nth-child(1) {
        text-align: center; }

    .block-overlay {
        background: rgba($block-color-blue, .8); }

    .block-light1 + & {
        @include angled-edge('inside top', 'lower right', $block-color-light1); }

    .block-light2 + & {
        @include angled-edge('inside top', 'lower right', $block-color-light2); }

    .block-default + & {
        @include angled-edge('inside top', 'lower right', white); }

    .separator {
        background: rgba($color-yellow, .4);
        height: 1px;
        margin: 20px 0;
        display: none; }

    .social {
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;

        a {
            margin: 0;
            margin-right: 10px; }

        h6 {
            display: none; }

        [class^="icon-"] {
            width: 22px; } } }

.footer-link {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    display: block;
    margin-bottom: 50px; }

.footer-bullet {
    li {
        padding-left: 20px;
        text-align: left; }

    li:before {
        position: absolute;
        left: 0;
        top: -7px;
        font-size: 19px;
        content: "■";
        color: $color-yellow; }

    & + .footer-link {
        margin-left: 20px; } }

// Responsive

@media (min-width: $screen-small) {
    .footer {
        padding-top: 150px;

        .col {
            width: 25%;
            float: right; }

        .col-4 {
            text-align: left;
            padding-left: 15px; }

        p {
            padding-right: 20px;
            max-width: 400px; }

        li {
            margin-bottom: 15px;
            text-align: left; }

        .col-1 {
            padding-right: 15px; }

        .col-3 {
            margin-top: 50px;
            li {
                margin-bottom: 8px; } }

        .col-5 {
            width: 100%;
            padding-left: 15px;
            text-align: left; }


        h4 {
            margin-bottom: 30px;
            text-align: left; }

        .separator {
            width: 80%;
            display: block; }

        .item:nth-child(1) {
            text-align: left; }

        .social {
            border: none;
            text-align: left !important; }

        .footer-left-border {
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            padding-left: 10px; } } }

.footer-copyright {
    font-size: 10px !important;
    text-transform: capitalize;
    opacity: .8;
    width: 100%;

    a {
        text-transform: uppercase;
        color: lighten($color-blue, 12); } }

.footer-legals {
    margin-top: 50px;
    opacity: .8;

    .small-9 {
        border-top: 1px solid rgba(white, .2);
        padding-top: 20px; }

    li {
        margin: 0;
        font-size: 10px; } }

@media (min-width: $screen-medium) {
    .footer {
        .col {
            width: 20%; }

        .col-4 {
            padding-left: 25px; } } }

@media (min-width: $screen-large) {
    .footer {
        p, ul {
            padding-right: 40px; } }

    .footer-link {
        margin-top: 30px !important; } }


