.pagination {
    margin: 0em;
    vertical-align: middle;
    font-weight: normal;
    line-height: 1 !important;

    .item {
        border: 1px solid $color-blue;
        padding: 8px;
        text-align: center;
        width: 35px;
        height: 35px;
        display: inline-block;
        margin: 1px;


        a {
            display: block; } }

    .disabled {
        opacity: .5; }

    .active {
        background: $color-blue;
        color: white !important;
        border-color: $color-blue; } }
