.login {
    background: white;
    position: relative;
    z-index: 1000 !important;
    margin: -90px auto -120px;
    max-width: 450px;
    padding: 30px;
    border-radius: 5px;

    .form-actions {
        text-align: center;
        padding: 5px 0; }

    .form-row {
        padding: 10px 0; }

    .button {
        margin: 1px; }

    .button-small {
        margin: 8px 0;
        width: 80%; }

    .form-checkboxes {
        margin-top: -5px;
        text-align: center; }

    .form-errors {
        margin: 0;
        margin-top: 10px;
        text-align: center; }

    .form-hint {
        display: block;
        text-align: left; } }

.login-close {
    position: absolute;
    top: 20px;
    right: 25px;

    i {
        font-size: 30px; } }

.login-forget {
    display: block;
    font-size: 10px;
    text-align: center;
    padding: 10px 0 15px; }

.login-logo {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 80px;
    margin-top: -50px; }

.login-or {
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
    position: relative;

    span {
        font-size: 10px;
        padding: 5px 10px;
        display: inline-block;
        background: white;
        z-index: 2;
        position: relative;
        color: #000; }

    &:after {
        content: '';
        display: block;
        top: 50%;
        left: 0;
        width: 100%;
        position: absolute;
        border-bottom: 1px dotted #000;
        z-index: 1; } }
