// Defining images default rotation angle
 :root {
    --rotation-angle: 15deg; }

.no-display {
    display: none !important; }

.error404-image {
    position: relative;
    width: 100%;
    z-index: 100;
    margin: -150px 0 -200px;

    @media(min-width: $screen-small) {
        width: 500px; } }

.title-link {
    position: absolute;
    bottom: 4px;
    right: 0;
    text-transform: none;
    font-size: 12px;
    font-weight: 700;

    i {
        font-size: 14px;
        vertical-align: top; } }

.stabilo {
    background: $color-yellow;
    padding: 1px 8px;
    color: $color-black;
    font-weight: 800;
    white-space: nowrap;
    text-transform: uppercase;
    display: inline-block; }

.centered {
    text-align: center; }

.bold {
    font-weight: bold; }

*:focus {
    outline: none; }

#please-wait {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    // Display will be turned to 'flex' via javascript
    display: none;
    align-items: center;
    p {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        margin: auto;
        width: 250px;
        height: 80px;
        background-color: white;
        border: 1px solid black;
        span {
            margin: auto;
            font-size: 1.3rem;
            user-select: none; } } }

.blue-font {
    color: $color-blue !important; }

#private-dashboard div.checkbox {
    label {
        margin-top: 30px !important;
        line-height: 1.3rem;
        font-size: 0.9rem;
        span {
            text-transform: none;
            color: lightgrey; } }
    input[type=checkbox] {
        transform: scale(1.25);
        margin-top: 10px; } }

#block-process {
    div {
        background-color: #EEFCFF; }
    h3 {
        font-size: 16px; }
    p {
        font-size: 0.85rem;
        &.intro {
            color: black; } }
    .stabilo {
        white-space: normal; }
    .container > div {
        padding: 30px 5px;
        margin-left: auto;
        margin-right: auto; }
    .container, #block2, #block3, .arrow, .arrow div {
        background-color: transparent; }
    svg {
        margin-bottom: 15px; }
    .arrow {
        clear: both;
        svg {
            height: 30px;
            margin-bottom: 0; } }
    .material-icons {
        font-size: 2.2rem;
        color: $color-blue; }
    #block1, #block2 {
        h3 {
            text-transform: none;
            font-weight: 700;
            letter-spacing: 0.15rem;
            color: $color-blue; } }
    #block2 i {
        clear: both;
        display: block; }
    #block3a, #block3b {
        margin: 0 10px;
        padding: 30px 15px;
        width: 50%; }
    .flex-container {
        display: flex; }
    .stabilo {
        text-transform: none; }
    h3.stabilo {
        margin-bottom: 30px; }
    .container h2 {
        font-size: 1.2rem;
        & .stabilo {
            text-transform: uppercase; } }
    .button {
        margin-top: 20px; }
    .button-stabilo {
        border: none;
        background: $color-yellow;
        color: black; }
    .block-title {
        width: 100%;
        h3 {
            margin-left: auto;
            margin-right: auto; } }
    #arrow-border {
        width: 49%;
        height: 35px;
        position: absolute;
        bottom: -48px;
        right: 1%;
        background: transparent;
        border-left: 1px solid $color-blue;
        border-bottom: 1px solid $color-blue; }
    #block1 svg {
        height: 70px; }
    #block2 > svg {
        height: 80px; }
    #block3 {
        padding: 30px 0 0 0;
        svg {
            height: 40px; } }
    #block4 {
        width: 70%;
        svg {
            height: 45px; } }
    #block5 {
        svg {
            height: 50px; }
        #block5a, #block5b, #block5c {
            padding-left: 8px;
            padding-right: 8px; } }
    #block6 {
        width: 80%;
        svg {
            height: 60px; } }
    #block7, #block8, #block10 {
        svg {
            height: 60px; } }
    #block9 {
        width: 70%;
        svg {
            height: 60px; } }
    #block11 {
        width: 80%;
        svg {
            height: 40px; } } }

#contact-index {
    h2 {
        margin-bottom: 40px; } }

#contact-confirm {
    .confirm-text {
        text-align: center;
        p {
            font-size: 1rem;
            font-weight: bold;
            color: $color-blue;
            margin-top: 20px;
            margin-bottom: 40px; } } }

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 10px 0 10px;
  margin-bottom: 20px; }


@media (min-width: $screen-small) {
    .left {
        text-align: left; }
    .right {
        text-align: right; }
    #block-process {
        .container > div {
            padding: 30px; }
        h3 {
            font-size: 18px; }
        p {
            font-size: 1rem; }
        .stabilo {
            white-space: nowrap; }
        #block3a, #block3b {
            margin: 0 15px;
            padding: 30px; }
        #block5a, #block5b, #block5c {
            padding-left: 15px;
            padding-right: 15px; } } }
