.text {
    h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin-bottom: 10px;
        text-transform: none;
        font-weight: 900;
        line-height: 1.3;

        &:not(:first-child()) {
            margin-top: 25px; } }

    h2, h3 {
        text-transform: uppercase; }

    p, ul, ol, dl {
        line-height: 1.7;
        font-size: 14px;
        margin-bottom: 15px; }

    li {
        margin-left: 20px; }

    ul li {
        list-style: square; }

    ol li {
        list-style: decimal !important; }

    a {
        color: $color-blue; }

    // img
    //     width: 100% !important
    //     height: auto !important
 }    //     margin-top: 0 !important
