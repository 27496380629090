// :-webkit-scrollbar
//     visibility: visible
// ::-webkit-scrollbar
//     visibility: visible

.splash {
    padding: 150px 0 125px;
    //overflow: hidden

    form {
        text-align: center;
        margin: 30px 0 0 0; }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #444 inset;
        border-color: white; }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        overflow: hidden;

        .touch-device & {
            display: none; } }

    h1 {
        text-align: center;
        font-weight: 900;
        font-size: 30px;
        line-height: 1.3;

        strong {
            color: $color-blue; }

        small {
            display: block;
            line-height: 1.5;
            font-size: 26px; } }

    h2 {
        margin: 25px 0;
        font-weight: 300;
        font-size: 2.5rem;
        //text-transform: none
        font: 16px "Andale Mono", "Lucida Console", Monaco, Courier, monospace;
        line-height: 1.6; } }

.splash-buttons {
    text-align: center;
    margin-top: 35px;
    margin-bottom: -20px;

    .button-basic {
        opacity: .9;
        color: white;
        border-color: white; } }

.splash-tagline {
    strong {
        color: $color-blue; }

    .space {
        display: inline-block;
        width: 4px; }

    // .new
    //     font-weight: 900
 }    //     color: lighten($color-lightblue, 3)

.splash-search-container {
    display: inline-block;
    position: relative;
    width: 100%; }

.splash-search {
    border-radius: 5px;
    border: 1px solid rgba(white, .2);
    background: rgba(white, .08) url(/img/icons/search.png) no-repeat;
    background-position: 15px center;
    background-size: 28px 28px;
    color: rgba(white, .9);
    padding: 20px;
    padding-left: 55px;
    padding-right: 55px;
    width: 100%; }

.splash-search-button {
    background: rgba(white, .1);
    border: 1px solid rgba(white, .2);
    color: white;
    border-radius: 4px;
    font-weight: 900;
    padding: 16px 15px 15px;
    margin-left: -100px;
    text-transform: uppercase;
    position: absolute;
    right: 5px;
    top: 6px;
    cursor: pointer;
    transition: .5s background;
    outline: 0;
    font-size: 11px;

    &:hover {
        background: $color-blue; } }

.splash-checkboxes {
    display: block;
    margin-top: 20px;
    margin-bottom: -40px;
    color: rgba(white, 1);

    label {
        margin: 5px;
        cursor: pointer;
        display: inline-block;
        font-weight: 600; }

    input {
        margin: 5px; } }

.no-touch-device .splash video {
    display: block; }

.splash-tagline-larger-font {
    font-size: 18px;
    //text-decoration: underline
    border-bottom: 3px double; }

//@media (min-width: $screen-tiny)
@media (min-width: $screen-small) {
    .splash {
        h1 {
            font-size: 42px;

            small {
                font-size: 35px; } }

        form {
            margin: 30px 0 0 0; } }

    .splash-search-container {
        width: 70%; }

    .splash-search {
        background-position: 25px center;
        font-size: 1.2em;
        padding: 20px;
        padding-left: 70px; }

    .splash-search-button {
        font-size: 14px; } }

//@media (max-width: $screen-tiny)
@media (max-width: $screen-small) {
    .splash-checkboxes {
        line-height: 1.7;

        label:nth-child(3):after {
            content: '';
            display: block; } } }
