#faq-index {
    .intro .button {
        margin-top: 2em; }

    p {
        margin-bottom: 10px; }

    .faqs {
        margin-top: 50px; }

    .text {
        h4 {
            font-weight: 700; }

        .faq-answer {
            margin-bottom: 25px;
            padding-left: 25px;
            border-left: 8px solid $color-blue;
            margin-left: 2px; } } }
