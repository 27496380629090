$color: (dark: #3f444c, light: #4698fc, gray: #eef4f7);

@mixin square($w, $h) {
    width: $w;
    height: $h; }

.about-history {
    margin-bottom: 25px; }

.history.hidden-tiny {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 50px 50px 60px;
    & > li {
        position: relative;
        width: calc(100% / 10);
        &:nth-of-type(even) {
            & > .content {
                transform: translateY(calc(-100% - 24px));
                &::before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    display: block;
                    border: 13px solid #4698fc;
                    border-top-color: rgb(63, 68, 76);
                    border-right-color: rgb(63, 68, 76);
                    border-bottom-color: rgb(63, 68, 76);
                    box-sizing: border-box;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    border-bottom-color: #4698fc;
                    position: absolute;
                    left: -17px;
                    top: -20px; } }
            & > .content + .history-text-container {
                top: -172px;
                .history-text {
                    position: absolute;
                    bottom: 0; } } }
        &:nth-of-type(odd) {
            & > .content::before {
                content: '';
                width: 5px;
                height: 5px;
                display: block;
                border: 13px solid #4698fc;
                border-top-color: rgb(63, 68, 76);
                border-right-color: rgb(63, 68, 76);
                border-bottom-color: rgb(63, 68, 76);
                box-sizing: border-box;
                border-left-color: transparent;
                border-right-color: transparent;
                border-top-color: #4698fc;
                border-bottom-color: transparent;
                position: absolute;
                left: -17px;
                top: 88px; }
            & > .content + .history-text-container {
                top: 112px; } }
        .month-label {
            position: absolute;
            height: 80px;
            left: -24px;
            z-index: 99;
            color: white;
            font-weight: bold; } } }

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    &::after {
        content: '';
        display: block;
        clear: both; }
    .year {
        color: map-get($color, light);
        background: map-get($color, dark);
        display: block;
        position: relative;
        padding-left: 24px;
        line-height: 18px;
        font-weight: bold;
        font-size: 18px;
        &.last-item {
            background: linear-gradient(0.25turn, #3f444c, #fff); }
        &::before {
            content: '';
            @include square(60px, 60px);
            position: absolute;
            background: map-get($color, light);
            border-radius: 50%;
            left: -33px;
            top: calc( (60 - 18) / 2 * -1px ); }
        &.new {
            border-radius: 0 24px 24px 0;
            &::after {
                content: '';
                @include square(5px, 5px);
                display: block;
                border: 20px solid map-get($color, dark);
                box-sizing: border-box;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                right: -30px;
                top: -9.5px; } } }
    .content {
        height: 70px;
        border-left: 8px solid map-get($color, light);
        margin-left: -8px;
        padding: 15px 0;
        padding-right: 24px;
        & > li {
            padding-left: 20px;
            border-bottom: 4px solid map-get($color, gray);
            padding-bottom: 8px;
            position: relative;
            margin-left: -8px;
            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0; }
            &::before {
                content: '';
                display: block;
                position: absolute;
                @include square(3px, 3px);
                border-radius: 50%;
                background: map-get($color, gray);
                left: 2px;
                top: 8px; } }
        &::before {
            content: '';
            width: 5px;
            height: 5px;
            display: block;
            border: 13px solid #4698fc;
            border-top-color: rgb(63, 68, 76);
            border-right-color: rgb(63, 68, 76);
            border-bottom-color: rgb(63, 68, 76);
            box-sizing: border-box;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            border-bottom-color: #4698fc;
            position: absolute;
            left: -17px;
            top: -20px; } } }

.history-text-container {
    position: absolute;
    width: 120px;
    height: 80px;
    left: -65px;
    .history-text {
        color: black;
        text-align: center;
        font-size: 1rem; } }

ul.history.visible-tiny {
    display: block;
    margin: 40px 40px 0 40px;
    & > li {
        width: 100%;
        &:nth-of-type(even) {
            .content {
                transform: none; } } }
    .year {
        color: white;
        background: map-get($color, dark);
        display: block;
        position: relative;
        padding-left: 24px;
        line-height: 24px;
        font-weight: bold;
        font-size: 16px;
        &::before {
            content: '';
            @include square(40px, 40px);
            position: absolute;
            background: map-get($color, light);
            border-radius: 50%;
            left: -24px;
            top: calc( (40 - 24) / 2 * -1px ); }
        &.new {
            border-radius: 0 24px 24px 0;
            &::after {
                content: '';
                @include square(5px, 5px);
                display: block;
                border: 20px solid map-get($color, dark);
                box-sizing: border-box;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                right: -30px;
                top: -9.5px; } } }
    .content {
        border-left: 8px solid map-get($color, light);
        margin-left: -8px;
        padding: 15px 0;
        padding-right: 24px;
        > li {
            padding-left: 20px;
            border-bottom: 4px solid map-get($color, gray);
            padding-bottom: 8px;
            position: relative;
            margin-left: -8px;
            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0; }
            &::before {
                display: none; } }
        &::before {
            display: none; } } }

@media screen and (max-width: 1140px) {
    ul.history > li {
        width: calc( 100% / 10 ); } }
@media screen and (max-width: 991px) {
    ul.history > li {
        width: calc( 100% / 10 );
        .month-label {
            left: -21px;
            font-size: 0.8rem; } }
    ul .year::before {
        width: 50px;
        height: 50px;
        left: -28px;
        top: calc( (50 - 18) / 2 * -1px); } }

