.button {
    display: inline-block;
    padding: 12px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    text-align: center;
    appearance: none;
    background: none;
    cursor: pointer;
    font-weight: 600;

    &:first-child {
        margin-left: 0; }

    &:last-child {
        margin-right: 0; }

    i {
        vertical-align: middle;
        margin-top: -3px;
        font-size: 16px;
        margin-right: 5px;
        width: 15px;
        text-align: center; }

    strong {
        font-weight: 800; }

    &.disabled {
        cursor: default; }

    &[disabled] {
        opacity: .6; } }

.button-small {
    padding: 10px 15px;
    font-size: 10px; }

.button-basic {
    border-color: $color-gray;
    color: $color-gray; }

.button-primary {
    border-color: $color-blue;
    color: $color-blue; }

.button-fill-primary {
    border-color: $color-blue;
    background: $color-blue;
    color: white !important; }

.button-secondary {
    border-color: $color-yellow;
    color: $color-yellow; }

.button-fill-secondary {
    border-color: $color-yellow;
    background: $color-yellow;
    color: black !important; }

.button-block {
    width: 100%;
    display: inline-block; }

.button-facebook,
.button-github,
.button-linkedin {
    color: white !important;
    border: none;
    margin-bottom: 10px; }

.button-facebook {
    background: #3B5998;
    border: 2px solid #3B5998; }

.button-github {
    background: #333;
    border: 2px solid #333; }

.button-linkedin {
    background: #1D87BD;
    border: 2px solid #1D87BD; }

.button-left {
    text-align: left; }

.buttons-horizontal {
    margin-bottom: 20px;

    .button {
        margin-right: 10px;
        display: inline-block; }

    .button:last-child {
        margin-right: 0; }

    @media (max-width: $screen-tiny) {
        .button {
            margin-right: 0px;
            margin-bottom: 10px;
            display: block;
            text-align: center; } }

    @media (min-width: $screen-tiny) and (max-width: $screen-small) {
        display: flex;

        .button {
            flex: 1 1;
            margin-right: 15px;
            text-align: center; } } }

.buttons-vertical {
    .button {
        display: block;
        margin-bottom: 15px; } }

// Round buttons

.buttons-round {
    text-align: center; }

.button-round-wrapper {
    text-align: center;
    display: inline-block;
    margin: 5px 10px;
    width: 175px;
    line-height: 1.5;

    .button-round {
        display: inline-block;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        text-align: center;
        line-height: 50px;
        border: 2px solid transparent;

        i {
            font-size: 34px;
            display: inline-block;
            margin-top: -7px;
            margin-right: -1px;
            width: 35px; } }

    &.brw-big {
        margin: -10px 10px 5px 10px;
        width: 300px;

        .button-round {
            width: 95px;
            height: 95px;
            min-width: 95px;
            min-height: 95px;
            border: 3px solid $color-blue;
            outline: none;

            & svg {
                width: 70px;
                height: auto;
                display: inline-block;
                margin-top: 7px;
                margin-right: -1px; }

            &:hover {
                border-color: $color-yellow;
                & svg .st0 {
                    fill: $color-yellow; } } }

        & span {
            font-size: 1.1rem;
            font-weight: 900; } }

    .button-favorite i {
        margin-top: -1px; }

    .button-application i {
        margin-top: -2px;
        margin-left: 0px; }

    .button-newsletter i {
        margin-top: 0px; }

    .button-round-basic {
        border: 2px solid $color-blue;
        background: white;
        color: $color-blue; }

    .button-round-primary {
        background: $color-blue;
        color: white;
        border: 2px solid $color-blue; }

    .button-round-secondary {
        background: $color-yellow;
        color: black;
        box-shadow: 0 0 0 3px white; }

    span {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        margin-top: 10px;
        font-weight: 600; } }

// button upload

.button-upload {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    text-align: left;

    input {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer; } }

.reset-email-inline-button {
    display: inline-block;
    button, a {
        height: 2rem;
        padding: 6px 10px;
        margin: 5px 0;
        white-space: nowrap; } }

.centered form.wide-form {
    max-width: 100%; }
