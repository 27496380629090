.block {
    padding: 50px 0 50px;
    position: relative;

    p:not(.intro):last-child() {
        margin-bottom: 0; } }

.block-default {
    background: #fff;

    a {
        color: $color-blue; } }

.block-text {
    h1, h2, h3, h4, h5, h6, p {
        text-align: left; }

    p, ul, blockquote {
        font-size: 16px;
        margin-bottom: 20px; } }

.block-strong {
    background: $block-color-brown url(/videos/video.jpg);
    background-size: cover;
    color: white;

    h2, h3, h4, p {
        color: white; }

    p {
        font-size: 13px !important; }

    i {
        font-size: 50px;
        margin-bottom: 5px;
        color: lighten($color-lightblue, 5); }

    .block-overlay {
        background: rgba($block-color-blue, .8); }

    .block-hover {
        outline: none;
        display: block;

        &:hover {
            background: $color-yellow;

            i {
                color: black; }

            h3, p {
                color: black !important; } } } }

.block-light1 {
    background: $block-color-light1; }

.block-light2 {
    background: $block-color-light2; }

.block-light3 {
    background: $block-color-light3; }

// Overlay

.block-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    + div {
        position: relative;
        z-index: 1;
        &.search-form {
            z-index: 9;
            user-select: none; }
        &.splash-search {
            user-select: auto; } } }
// Maps

.block-map {
    .logo {
        margin-bottom: 10px; }

    address {
        border-radius: 10px;
        padding: 20px 40px;
        background: white;
        font-size: 14px;
        margin-bottom: 20px;
        position: relative;
        display: inline-block;
        margin-top: -20px;
        width: 90%; }

    @media (min-width: $screen-small) {

        address {
            margin-top: -50px;
            width: auto; }

        .map {
            border: 5px solid white; }

        .row {
            padding: 0 $grid-gutter-width; } } }

// Splash

.block-splash {
    @include angled-edge('inside bottom', 'upper left', #fff);
    background: black url(/videos/video.jpg) top center;
    background-size: cover;
    color: white;

    .block-overlay {
        background: rgba(black, .6); }

    & + .block {
        padding-top: 10px; } }

// Enumeration

.block-enum {
    svg {
        height: 60px;
        margin-bottom: 20px; }

    &.block-enum-icons svg {
        height: 80px;
        margin-bottom: 10px; }

    h3 {
        white-space: nowrap; }

    .item {
        p {
            margin: 0; }

        svg {
            transform: scale(1); } } }

.block-compact {
    padding: 40px;

    .message,
    .form-row,
    .intro {
        margin-bottom: 20px; }

    .form-row {
        padding: 5px; } }

// Call to Action

.block-calltoaction {
    padding: 20px 0;

    h3 {
        white-space: nowrap; }

    p {
        font-size: 13px; }

    a {
        padding: 35px 30px;
        display: block;
        margin: 0 ($grid-gutter * -1);
        display: block;
        height: 100%;
        border-radius: 5px; }

    .touch-device .item:hover,
    .item:active {
        background: $color-yellow;
        cursor: pointer;

        h2, h3, h4, p {
            color: black; }

        svg g {
            fill: $color-black; } } }

@media (min-width: $screen-small) {
    .block-calltoaction {
        .row {
            display: flex; } } }

