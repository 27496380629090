.fr-view {
    padding: 0;

    p, ul, ol {
        line-height: 1.7; }

    h1, h2, h3, h4 {
        text-transform: uppercase; }

    ul, ol {
        padding-left: 20px; }

    em {
        font-style: italic; }

    .fr-video {
        position: relative;
        padding-bottom: 56%;
        padding-top: 5px;
        height: 0;

        iframe {
            background: #000;
            border: 8px solid white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; } }

    .picture-large {
        width: 100% !important;
        margin: 20px 0 !important;
        height: auto;
        background: #eee;
        min-height: 30px;

        &:first-child {
            margin-top: 0; } }

    .picture-shadow {
        background: #eee;
        min-height: 30px;
        border: 10px solid white;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }

    .fr-fil {
        margin-right: 15px !important; }

    .fr-fir {
        margin-left: 15px !important; }

    .fr-dib {
        margin-top: 15px !important;
        margin-bottom: 15px !important; } }
