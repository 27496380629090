.sticker-primary,
.sticker-secondary {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 8px 12px;
    margin: 10px 0; }

.sticker-primary {
    background: $color-yellow;
    color: $color-black; }

.sticker-secondary {
    background: $color-black;
    color: white; }

.stickers {
    @include clearfix;

    .sticker-primary,
    .sticker-secondary {
        float: left;
        clear: left;
        margin: 0; }

    .sticker-primary {
        font-weight: 600;
        padding: 5px 12px;
        text-transform: none;
        font-size: 14px; }

    .sticker-secondary {
        text-transform: uppercase;
        font-weight: 600;
        padding: 8px 50px 8px 12px;
        font-size: 15px; } }
