.dashboard-block {
    margin-bottom: 30px;

    .table {
        line-height: 1.5; }

    h3 {
        font-size: 13px;
        margin: 3px 0;

        i {
            margin-right: 5px;
            background: $color-yellow;
            padding: 3px 5px;
            border-radius: 5px;
            font-size: 20px;
            overflow: hidden;
            text-align: center;
            max-width: 28px; } }

    .icon-before {
        color: black;
        font-size: 15px; } }

.dashboard-delete-account {
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 20px !important;
    display: block;

    button {
        display: block;
        font-size: 11px;
        text-align: left;
        color: $color-blue;
        cursor: pointer; } }

.dashboard-noresult {
    font-size: 12px;
    line-height: 2;
    padding: 30px;
    border-radius: 2px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 14px;
    text-transform: uppercase; }

