#companies-index {}

.companies-enums {
    .item p {
        padding-left: 30px; } }

.companies-stats {
    .item:nth-child(1) .circle-strong {
        padding-top: 3px;
        padding-right: 6px; }

    .item:nth-child(2) .circle-strong {
        padding-top: 4px;
        padding-right: 6px; }

    .item:nth-child(3) .circle-strong {
        padding-top: 0;
        padding-right: 4px; }

    .item:nth-child(4) .circle-strong {
        padding-top: 8px;
        padding-right: 4px; }

    .item:nth-child(5) .circle-strong {
        padding-top: 3px;
        padding-right: 1px; }

    .item:nth-child(6) .circle-strong {
        padding-top: 2px;
        padding-right: 3px; }

    .centered {
        margin-top: 40px; }

    @media (max-width: 992px) {
        .item {
            margin-bottom: 0; } } }

.companies-following {
    .circle-letter {
        margin-bottom: -45px;
        margin-left: -10px;
        position: relative;
        z-index: 100;
        border: 2px solid $color-blue;
        box-shadow: 0 2px 5px rgba(black, .15);
        color: $color-blue; }

    .sticker-primary,
    .sticker-secondary {
        padding-left: 40px;
        margin-bottom: 15px; }

    .dots {
        margin: 10px 0 30px;
        position: relative;
        opacity: 0;
        transition: all 1s ease-in-out;

        &.appeared {
            opacity: 1; }

        &:before {
            display: block;
            content: '';
            width: 100%;
            border: 2px solid darken($color-blue-bar, 4);
            position: absolute;
            top: 50%;
            transform: translateY(-50%); } }

    .dot:before {
        display: block;
        content: '';
        width: 14px;
        height: 14px;
        background: darken($color-blue-bar, 8);
        border-radius: 50%;
        margin: 0 auto; }

    .item {
        margin-bottom: 20px;
        transition: all 1s ease-in-out;
        opacity: 0;
        transform: translateY(100px);

        &.appeared {
            opacity: 1;
            transform: translateY(0); } }

    @media (min-width: $screen-small) {
        @for $i from 1 through 8 {
            .item:nth-child(#{$i}) {
                transition-delay: (150 * $i) * 1ms; } } } }

.companies-identity {
    border-top: 1px solid $color-lightblue !important;

    p {
        @media(min-width: $screen-small) {
            margin-top: 10px; }

        strong {
            color: darken($color-blue, 30);
            font-weight: 900; }

        em {
            font-weight: 500;
            color: black; } } }

.companies-identity-footer {
    p {
        text-align: center;
        margin-top: 40px;
        font-size: 32px;
        font-weight: 500;
        color: darken($color-blue, 10);

        @media(max-width: $screen-small) {
            font-size: 24px;

            br {
                display: none; } } } }

.what-can-we-do-block {
    margin-top: 40px; }

.companies-identity-list {
    margin-top: 40px;

    h3 {
        color: darken($color-blue, 30);
        text-align: left;
        font-size: 14px;
        border-bottom: 1px dotted gray;
        margin-bottom: 10px;
        padding-bottom: 10px; }

    ul {
        margin-left: 20px; }
    li {
        list-style: square;
        line-height: 1.5; } }
