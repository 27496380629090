$timeline-bar-width: 16px;
$timeline-padding: 20px;
$timeline-transition: .5s;

.timeline {
    position: relative; }

.timeline-all {
    @include clearfix;
    display: block;
    line-height: 1.4;
    z-index: 100;
    width: 50%;
    margin-top: 15px;
    padding: 0px 120px;
    position: absolute;
    right: 0;
    display: none; }

.timeline-all-link {
    color: $color-text !important;

    strong {
        display: inline-block;
        font-weight: 800;
        margin-bottom: 5px;
        color: black; }

    i {
        float: left;
        font-size: 70px;
        margin-right: 15px;
        margin-top: -5px;
        color: $color-yellow; }

    strong, i {
        transition: all .25s; } }

.timeline-all:hover {
    strong {
        color: black; }

    i {
        color: $color-blue; } }

.timeline-number {
    font-size: 54px;
    font-weight: 800;
    color: $color-blue;
    float: left;
    margin: -15px 0;
    margin-right: 15px; }

div:not(#homepage-index) {
    .timeline-starting {
        @for $i from 1 through 10 {
            .timeline-wrapper:nth-child(#{$i}) {
                transition-delay: (200 * $i) * 1ms; } } } }

.timeline-year {
    padding: 20px;
    position: relative;
    background: white;
    font-size: 40px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    width: 150px;
    font-weight: 800;
    transform: scale(0);
    transition: $timeline-transition all;

    &.appeared {
        opacity: 1;
        transform: scale(1); } }

.timeline-past {
    margin: 50px 0;
    border-top: 8px dotted rgba($color-blue-bar, 1);
    position: relative; }

.timeline-axe-x {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 20px);
    width: 10px;
    z-index: 0; }

.timeline-axe-x,
.timeline-axe-y {
    background: $color-blue-bar; }

.timeline-wrapper {
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: $timeline-transition all;
    transition-delay: .25s;

    &.appeared {
        opacity: 1;
        transform: translateY(0); } }

.timeline-block {
    position: relative;
    border: 1px solid #bbb;
    box-shadow: 0 0 15px rgba(black, .2);
    width: 100%;
    text-align: left;
    background: white;
    z-index: 1;
    display: inline-block;

    a {
        color: inherit; }

    a[href="#"] {
        cursor: default; }

    h5 {
        text-align: left;
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 5px; }

    p:not(:last-child) {
        margin-bottom: 5px; }

    time {
        display: block;
        font-size: 13px;
        color: black;
        font-weight: 600; } }

.timeline-block-wrapper {
    display: flex; }

.timeline-bar {
    background: $color-yellow;
    width: $timeline-bar-width;
    margin: 3px;

    .timeline-stat & {
        background: $color-blue; } }

.timeline-image {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: stretch;
    flex: 1; }

.timeline-image-wrapper {
    display: block;
    width: 100%;
    min-height: 100%;
    background: white no-repeat center center;
    background-size: contain; }

.timeline-details {
    padding: $timeline-padding;
    flex: 2; }

.timeline-month {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 62px;
    border-radius: 50%;
    border: 5px solid white;
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 0 10px rgba(black, .15);
    margin: 100px auto;
    background: $color-yellow;

    .timeline-stat & {
        background: $color-blue; } }

.timeline-wrapper {
    text-align: center;

    .timeline-axe-y {
        right: 0; }

    .timeline-month {
        margin: 10px auto; } }

@media (min-width: $screen-medium) {
    .timeline-wrapper {
        margin: 0; }

    .timeline-axe-y {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        width: 50%; }

    .timeline-wrapper {
        margin: -10px 0;
        text-align: left; }

    .timeline-block {
        width: calc(50% - 80px); }

    .timeline-month {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }

    .timeline-wrapper.timeline-even {
        text-align: right;

        .timeline-axe-y {
            right: 0; }

        .timeline-month {
            margin: 0; } }

    .timeline-wrapper.timeline-odd {
        .timeline-bar {
            order: 3; }

        .timeline-image {
            order: 2; }

        .timeline-axe-y {
            left: 0; }

        .timeline-month {
            margin: 0; } }

    .timeline-all {
        display: block; } }
