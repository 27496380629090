form {
    line-height: 1.3;

    .centered & {
        margin: 0 auto;
        max-width: 50%; } }

input,
textarea,
select {
    appearance: none;
    padding: 12px 15px;
    background: #fcfcfc;
    width: 100%;
    border: 1px solid $color-blue;
    border-radius: 5px; }

input[type="radio"],
input[type="checkbox"] {
    display: inline-block;
    border: 1px solid #000;
    width: auto;
    margin-right: 5px; }

input[type="radio"] {
    appearance: radio; }

input[type="checkbox"] {
    appearance: checkbox; }

input[type="file"]::-webkit-file-upload-button {
    cursor: pointer; }

select {
    border-radius: 0;
    height: 40px;
    padding: 0;
    padding-left: 30px;
    background-image: url(../img/select.png);
    background-repeat: no-repeat;
    background-position: left 5px top 50%;
    background-size: 20px; }

label {
    font-weight: bold;
    font-size: 13px;
    display: block;
    letter-spacing: 0.03em;
    margin-bottom: 5px;
    text-transform: uppercase; }

.form-block {
    padding: 10px 0;
    border-radius: 3px;
    margin: 15px -5px;
    border: 1px solid darken($color-lightblue, 5); }

.form-row {
    padding: 15px 0; }

.form-actions {
    padding: 15px 0;
    text-align: center;

    @media (min-width: $screen-small) {
        text-align: right; } }

.form-error {
    border: 3px solid $color-red; }

.form-errors {
    margin-top: 8px;
    color: $color-red;

    li {
        font-size: 12px; }

    li::first-letter {
        text-transform: uppercase; } }

.form-hint {
    font-size: 11px;
    margin-top: 8px; }

.form-checkboxes,
.form-checkboxes-inline {
    margin-top: 10px;

    label {
        font-weight: normal;
        text-transform: none; } }

.form-checkboxes-inline {
    label {
        display: inline-block;
        margin-right: px; } }
