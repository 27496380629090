*, *:before, *:after {
    box-sizing: border-box; }

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-text-size-adjust: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }

body {
    line-height: 1; }

a {
    color: inherit; }

ol, ul {
    list-style: none; }

blockquote, q {
    quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
    border-collapse: collapse;
    border-spacing: 0; }
