.meta-list {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;

    li {
        padding: 15px 0 15px 50px;
        position: relative;
        font-size: 13px;

        &:nth-child(odd) {
            background: rgba(#F6F9FC, .8); }

        &:nth-child(even) {
            background: rgba(#fff, .8); } }

    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        vertical-align: middle;
        color: $color-blue;
        font-size: 28px;
        float: left; } }
