#about-index {
    .block-splash {
        background-image: url(/img/splashes/address.jpg);
        background-position: center bottom; }

    .intro .button {
        margin: 25px 0 10px; }

    .logo {
        width: 180px;
        margin: -5px auto 10px;
        display: block; } }

.about-identity {


    p {
        @media(min-width: $screen-small) {
            margin-top: 10px; }

        strong {
            color: darken($color-blue, 20);
            font-weight: 900; }

        em {
            font-weight: 500;
            color: black; } } }

.about-identity-footer {
    p {
        text-align: center;
        margin-top: 40px;
        font-size: 32px;
        font-weight: 500;
        color: darken($color-blue, 10);

        @media(max-width: $screen-small) {
            font-size: 24px;

            br {
                display: none; } } } }

.about-identity-bullets {
    padding: 30px 0;

    h2 {
        margin-bottom: 30px; }

    i {
        font-size: 48px;
        color: darken($color-blue, 5);
        margin-bottom: 10px; }

    p {
        font-weight: 600; }

    .row {
        margin-bottom: 20px; } }
