.table {
    line-height: 1.8;
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #dedede;

    &.table-small {
        font-size: 12px;
        line-height: 1.3; }

    a {
        color: darken($color-blue, 15) !important; }

    strong {
        font-weight: 800; }

    tr {
        border-top: 1px solid #dedede; }

    th, td {
        padding: 15px 0;
        text-align: left;
        position: relative; }

    &.table-small {
        th, td {
            padding: 9px 0; } }

    th {
        white-space: nowrap;
        font-weight: 700;
        text-transform: uppercase; }

    &.table-stripped {
        tr:nth-child(odd) {
            background-color: #f8f8f8; }

        tr:nth-child(even) {
            background-color: #fff; }

        th, td {
            padding: 15px; }

        &.table-small {
            th, td {
                padding: 9px; } } }

    td:first-child {
        padding-left: 25px; }

    thead tr {
        font-weight: 800;
        text-transform: uppercase;
        background: white !important; }

    tfoot tr {
        border-top: 1px solid #ddd;
        background: white !important; }

    i {
        margin-right: 5px;
        color: $color-blue; }

    .job-created-at i {
        display: none; } }

@media (max-width: $screen-small) {
    .table {
        margin-left: -15px;
        width: calc(100% + 30px);

        thead th {
            display: none; }

        td, th {
            display: block;
            padding: 2px 15px !important; }

        &.table-small {
            th, td {
                padding: 6px 5px !important; } }

        td:first-child() {
            margin-top: 15px; }

        td:nth-child(3) {
            margin-bottom: 15px; }

        .table-br, .table-br:before, .table-br:after {
            content: ' '; }

        .table-br:before {
            display: inline;
            content: ' — '; }

        .table-action {
            display: none; }

        .job-created-at i {
            display: inline-block; } } }

.table-action {
    vertical-align: middle;

    i {
        font-size: 32px;
        color: $color-blue; }

    a {
        display: inline-block; } }

.table-bar {
    width: 8px;
    left: 0;
    top: 0;
    bottom: 0;
    border: 2px solid white;
    background: #ddd;
    position: absolute;

    @media (max-width: $screen-small) {
        display: none; }

    .table-status-closed & {
        background: $color-red; }

    .table-status-won & {
        background: $color-green; }

    .table-status-open & {
        background: $color-yellow; } }

.table-noresult {
    text-align: center;
    padding: 150px 50px;
    width: 100%; }

.table-color {
    width: 1px;
    padding: 0;
    background: $color-yellow; }

.table-actions {
    text-align: center !important;
    width: 85px;
    white-space: nowrap;

    i {
        color: #999;

        &:hover {
            color: $color-lightblue; }

        &.important:hover {
            color: $color-red; } } }


