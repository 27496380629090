.shares {}

.addthis_inline_share_toolbox {
    height: 50px;
    display: block; }

@media (max-width: $screen-small) {
    .at-resp-share-element {
        text-align: center; } }

