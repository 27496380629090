#jobs-index {
    .icon-favorite {
        font-size: 16px; }

    .form-actions {
        margin-top: -40px;
        position: relative;
        z-index: 1; } }

#jobs-show {
    header .block-overlay {
        background: rgba(black, .7); } }

#jobs-index,
#jobs-show {
    .buttons-round {
        margin-top: -90px;
        margin-bottom: 40px;
        position: relative;
        z-index: 1; } }

#jobs-show {
    .buttons-round {
        margin-bottom: 50px; } }
