.youtube {
    text-align: center;
    margin-top: -90px;
    margin-bottom: -60px;
    position: relative;
    z-index: 10;

    iframe {
        background: #000;
        border: 5px solid white; }

    @media (min-width: $screen-small) {
        iframe {
            width: 600px;
            height: 340px; } } }
