.resume-block {
    form {
        max-width: 100%; } }

.resumes {
    text-align: left;
    padding-top: 8px; }

.resumes-application {
    text-align: center;
    border-top: 0;
    margin-bottom: 20px; }

.resume {
    border: 1px solid #ddd;
    width: 120px;
    height: 120px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 8px;
    overflow: hidden;
    text-align: center;
    line-height: 110px;
    position: relative;
    transition: .5s box-shadow;

    i {
        font-size: 48px; }

    &.selected {
        box-shadow: 0 10px 20px rgba($color-blue, .19), 0 6px 6px rgba($color-blue,.23), 0 0px 10px rgba($color-blue, .23);
        border: 1px solid $color-blue !important; } }

.resume-upload {
    position: relative;
    cursor: pointer;
    line-height: 115px;

    i {
        color: #ccc;
        cursor: pointer; }

    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        opacity: 0; } }

.resume-file {
    display: block;
    color: $color-blue; }

.resume-view,
.resume-delete {
    position: absolute;
    top: 8px;
    right: 5px;
    display: block;
    line-height: 1;

    i {
        color: #999;
        font-size: 20px; } }

.resume-view {
    left: 5px;
    right: auto; }

.resume-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    background: $color-lightyellow;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.resume-loading {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: 38px auto;
    width: 40px;
    height: 40px;
    background-color: $color-yellow;
    animation: resume-loading-anim .75s infinite ease-in-out; }

.resume-processing {
    i {
        visibility: hidden; }

    .resume-loading {
        visibility: visible; } }

@keyframes resume-loading-anim {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

