
$color-text: #4A4A4A;
$color-yellow: #FFD801;
$color-lightyellow: lightyellow;
$color-blue: #5FA6FC;
$color-lightblue: #CFE6FF;
$color-black: #111;
$color-gray: #666;
$color-blue-bar: darken(#EEF3F8, 2);

$color-red: #D81B60;
$color-green: #C0CA33;

$block-color-brown: #392A1F;
$block-color-blue: darken(#0C509E, 1);
$block-color-light1: lighten(#F0F7FF, .5);
$block-color-light2: lighten(#F0F7FF, 1);
$block-color-light3: lighten(#F0F7FF, 1.2);

@import url('https://fonts.googleapis.com/css?family=Raleway');

@import "../shared/base/reset";
@import "../shared/mixins/angles";
@import "../shared/mixins/clearfix";

@import "base/grid";
@import "base/elements";
@import "base/effects";
@import "base/responsive";
@import "base/tables";
@import "base/text";
@import "base/misc";
@import "base/form";

@import "vendors/googlemap";
@import "vendors/material-icons";
@import "vendors/froala";

@import "components/columns";
@import "components/blocks";
@import "components/topbar";
@import "components/header";
@import "components/splash";
@import "components/footer";
@import "components/buttons";
@import "components/circles";
@import "components/maps";
@import "components/social";
@import "components/timeline";
@import "components/history";
@import "components/inside";
@import "components/youtube";
@import "components/shadows";
@import "components/maps";
@import "components/jobs";
@import "components/paginations";
@import "components/messages";
@import "components/modals";
@import "components/shares";
@import "components/login";
@import "components/dashboard";
@import "components/resumes";
@import "components/blogs";
@import "components/metas";
@import "components/references";
@import "components/team";
@import "components/testimonies";
@import "components/stickers";
@import "components/portraits";

@import "screens/homepage";
@import "screens/faq";
@import "screens/about";
@import "screens/events";
@import "screens/companies";
@import "screens/private";
@import "screens/jobs";
