html {
    min-height: 100%; }

body {
    overflow: hidden;
    min-height: 100%;
    background: white;
    font: 14px "Raleway", sans-serif; // 400 500 600 800 900
    line-height: 1.2; }

a {
    text-decoration: none; }

strong {
    font-weight: 600; }

h1, h2, h3, h4, h5, h6 {
    text-align: center;
    text-rendering: geometricPrecision;
    position: relative;

    strong {
        font-weight: 900; } }

h2 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600; }

h3 {
    color: black;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 15px; }

h4 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;

    strong {
        font-weight: 900; } }

h6 {
    font-weight: 900;
    font-size: 14px; }

p {
    color: $color-text;
    font-size: 14px;
    line-height: 1.6; }

p.intro {
    font-size: 16px;
    color: #9B9B9B;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;

    strong {
        color: black;
        font-weight: 500; } }

#homepage-index p.intro strong {
    font-weight: 700; }

hr {
    background: rgba(black, .1);
    height: 1px;
    margin: 20px 0; }

::-webkit-file-upload-button {
    cursor:pointer {} }
