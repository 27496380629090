
a[href^="http://maps.google.com/maps"] {
    display:none !important
}

a[href^="https://maps.google.com/maps"] {
    display:none !important
}

a[href^="https://www.google.com/maps"] {
    display:none !important
}