@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway");
*, *:before, *:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

a {
  color: inherit;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@-ms-viewport {
  width: device-width;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.tiny-1,
.small-1,
.medium-1,
.large-1, .tiny-2,
.small-2,
.medium-2,
.large-2, .tiny-3,
.small-3,
.medium-3,
.large-3, .tiny-4,
.small-4,
.medium-4,
.large-4, .tiny-5,
.small-5,
.medium-5,
.large-5, .tiny-6,
.small-6,
.medium-6,
.large-6, .tiny-7,
.small-7,
.medium-7,
.large-7, .tiny-8,
.small-8,
.medium-8,
.large-8, .tiny-9,
.small-9,
.medium-9,
.large-9, .tiny-10,
.small-10,
.medium-10,
.large-10, .tiny-11,
.small-11,
.medium-11,
.large-11, .tiny-12,
.small-12,
.medium-12,
.large-12 {
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .tiny-1, .tiny-2, .tiny-3, .tiny-4, .tiny-5, .tiny-6, .tiny-7, .tiny-8, .tiny-9, .tiny-10, .tiny-11, .tiny-12 {
    float: left;
  }
  .tiny-1 {
    width: 8.3333333333%;
  }
  .tiny-2 {
    width: 16.6666666667%;
  }
  .tiny-3 {
    width: 25%;
  }
  .tiny-4 {
    width: 33.3333333333%;
  }
  .tiny-5 {
    width: 41.6666666667%;
  }
  .tiny-6 {
    width: 50%;
  }
  .tiny-7 {
    width: 58.3333333333%;
  }
  .tiny-8 {
    width: 66.6666666667%;
  }
  .tiny-9 {
    width: 75%;
  }
  .tiny-10 {
    width: 83.3333333333%;
  }
  .tiny-11 {
    width: 91.6666666667%;
  }
  .tiny-12 {
    width: 100%;
  }
  .tiny-pull-0 {
    left: auto;
  }
  .tiny-pull-1 {
    left: 8.3333333333%;
  }
  .tiny-pull-2 {
    left: 16.6666666667%;
  }
  .tiny-pull-3 {
    left: 25%;
  }
  .tiny-pull-4 {
    left: 33.3333333333%;
  }
  .tiny-pull-5 {
    left: 41.6666666667%;
  }
  .tiny-pull-6 {
    left: 50%;
  }
  .tiny-pull-7 {
    left: 58.3333333333%;
  }
  .tiny-pull-8 {
    left: 66.6666666667%;
  }
  .tiny-pull-9 {
    left: 75%;
  }
  .tiny-pull-10 {
    left: 83.3333333333%;
  }
  .tiny-pull-11 {
    left: 91.6666666667%;
  }
  .tiny-pull-12 {
    left: 100%;
  }
  .tiny-push-0 {
    right: auto;
  }
  .tiny-push-1 {
    right: 8.3333333333%;
  }
  .tiny-push-2 {
    right: 16.6666666667%;
  }
  .tiny-push-3 {
    right: 25%;
  }
  .tiny-push-4 {
    right: 33.3333333333%;
  }
  .tiny-push-5 {
    right: 41.6666666667%;
  }
  .tiny-push-6 {
    right: 50%;
  }
  .tiny-push-7 {
    right: 58.3333333333%;
  }
  .tiny-push-8 {
    right: 66.6666666667%;
  }
  .tiny-push-9 {
    right: 75%;
  }
  .tiny-push-10 {
    right: 83.3333333333%;
  }
  .tiny-push-11 {
    right: 91.6666666667%;
  }
  .tiny-push-12 {
    right: 100%;
  }
  .tiny-offset-1 {
    margin-left: 8.3333333333%;
  }
  .tiny-offset-2 {
    margin-left: 16.6666666667%;
  }
  .tiny-offset-3 {
    margin-left: 25%;
  }
  .tiny-offset-4 {
    margin-left: 33.3333333333%;
  }
  .tiny-offset-5 {
    margin-left: 41.6666666667%;
  }
  .tiny-offset-6 {
    margin-left: 50%;
  }
  .tiny-offset-7 {
    margin-left: 58.3333333333%;
  }
  .tiny-offset-8 {
    margin-left: 66.6666666667%;
  }
  .tiny-offset-9 {
    margin-left: 75%;
  }
  .tiny-offset-10 {
    margin-left: 83.3333333333%;
  }
  .tiny-offset-11 {
    margin-left: 91.6666666667%;
  }
  .tiny-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 768px) {
  
  .small-1,
  .small-2,
  .small-3,
  .small-4,
  .small-5,
  .small-6,
  .small-7,
  .small-8,
  .small-9,
  .small-10,
  .small-11,
  .small-12 {
    float: left;
  }
  .small-1 {
    width: 8.3333333333%;
  }
  .small-2 {
    width: 16.6666666667%;
  }
  .small-3 {
    width: 25%;
  }
  .small-4 {
    width: 33.3333333333%;
  }
  .small-5 {
    width: 41.6666666667%;
  }
  .small-6 {
    width: 50%;
  }
  .small-7 {
    width: 58.3333333333%;
  }
  .small-8 {
    width: 66.6666666667%;
  }
  .small-9 {
    width: 75%;
  }
  .small-10 {
    width: 83.3333333333%;
  }
  .small-11 {
    width: 91.6666666667%;
  }
  .small-12 {
    width: 100%;
  }
  .small-pull-0 {
    left: auto;
  }
  .small-pull-1 {
    left: 8.3333333333%;
  }
  .small-pull-2 {
    left: 16.6666666667%;
  }
  .small-pull-3 {
    left: 25%;
  }
  .small-pull-4 {
    left: 33.3333333333%;
  }
  .small-pull-5 {
    left: 41.6666666667%;
  }
  .small-pull-6 {
    left: 50%;
  }
  .small-pull-7 {
    left: 58.3333333333%;
  }
  .small-pull-8 {
    left: 66.6666666667%;
  }
  .small-pull-9 {
    left: 75%;
  }
  .small-pull-10 {
    left: 83.3333333333%;
  }
  .small-pull-11 {
    left: 91.6666666667%;
  }
  .small-pull-12 {
    left: 100%;
  }
  .small-push-0 {
    right: auto;
  }
  .small-push-1 {
    right: 8.3333333333%;
  }
  .small-push-2 {
    right: 16.6666666667%;
  }
  .small-push-3 {
    right: 25%;
  }
  .small-push-4 {
    right: 33.3333333333%;
  }
  .small-push-5 {
    right: 41.6666666667%;
  }
  .small-push-6 {
    right: 50%;
  }
  .small-push-7 {
    right: 58.3333333333%;
  }
  .small-push-8 {
    right: 66.6666666667%;
  }
  .small-push-9 {
    right: 75%;
  }
  .small-push-10 {
    right: 83.3333333333%;
  }
  .small-push-11 {
    right: 91.6666666667%;
  }
  .small-push-12 {
    right: 100%;
  }
  .small-offset-1 {
    margin-left: 8.3333333333%;
  }
  .small-offset-2 {
    margin-left: 16.6666666667%;
  }
  .small-offset-3 {
    margin-left: 25%;
  }
  .small-offset-4 {
    margin-left: 33.3333333333%;
  }
  .small-offset-5 {
    margin-left: 41.6666666667%;
  }
  .small-offset-6 {
    margin-left: 50%;
  }
  .small-offset-7 {
    margin-left: 58.3333333333%;
  }
  .small-offset-8 {
    margin-left: 66.6666666667%;
  }
  .small-offset-9 {
    margin-left: 75%;
  }
  .small-offset-10 {
    margin-left: 83.3333333333%;
  }
  .small-offset-11 {
    margin-left: 91.6666666667%;
  }
  .small-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  
  .medium-1,
  .medium-2,
  .medium-3,
  .medium-4,
  .medium-5,
  .medium-6,
  .medium-7,
  .medium-8,
  .medium-9,
  .medium-10,
  .medium-11,
  .medium-12 {
    float: left;
  }
  .medium-1 {
    width: 8.3333333333%;
  }
  .medium-2 {
    width: 16.6666666667%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-4 {
    width: 33.3333333333%;
  }
  .medium-5 {
    width: 41.6666666667%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-7 {
    width: 58.3333333333%;
  }
  .medium-8 {
    width: 66.6666666667%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-10 {
    width: 83.3333333333%;
  }
  .medium-11 {
    width: 91.6666666667%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-pull-0 {
    left: auto;
  }
  .medium-pull-1 {
    left: 8.3333333333%;
  }
  .medium-pull-2 {
    left: 16.6666666667%;
  }
  .medium-pull-3 {
    left: 25%;
  }
  .medium-pull-4 {
    left: 33.3333333333%;
  }
  .medium-pull-5 {
    left: 41.6666666667%;
  }
  .medium-pull-6 {
    left: 50%;
  }
  .medium-pull-7 {
    left: 58.3333333333%;
  }
  .medium-pull-8 {
    left: 66.6666666667%;
  }
  .medium-pull-9 {
    left: 75%;
  }
  .medium-pull-10 {
    left: 83.3333333333%;
  }
  .medium-pull-11 {
    left: 91.6666666667%;
  }
  .medium-pull-12 {
    left: 100%;
  }
  .medium-push-0 {
    right: auto;
  }
  .medium-push-1 {
    right: 8.3333333333%;
  }
  .medium-push-2 {
    right: 16.6666666667%;
  }
  .medium-push-3 {
    right: 25%;
  }
  .medium-push-4 {
    right: 33.3333333333%;
  }
  .medium-push-5 {
    right: 41.6666666667%;
  }
  .medium-push-6 {
    right: 50%;
  }
  .medium-push-7 {
    right: 58.3333333333%;
  }
  .medium-push-8 {
    right: 66.6666666667%;
  }
  .medium-push-9 {
    right: 75%;
  }
  .medium-push-10 {
    right: 83.3333333333%;
  }
  .medium-push-11 {
    right: 91.6666666667%;
  }
  .medium-push-12 {
    right: 100%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  
  .large-1,
  .large-2,
  .large-3,
  .large-4,
  .large-5,
  .large-6,
  .large-7,
  .large-8,
  .large-9,
  .large-10,
  .large-11,
  .large-12 {
    float: left;
  }
  .large-1 {
    width: 8.3333333333%;
  }
  .large-2 {
    width: 16.6666666667%;
  }
  .large-3 {
    width: 25%;
  }
  .large-4 {
    width: 33.3333333333%;
  }
  .large-5 {
    width: 41.6666666667%;
  }
  .large-6 {
    width: 50%;
  }
  .large-7 {
    width: 58.3333333333%;
  }
  .large-8 {
    width: 66.6666666667%;
  }
  .large-9 {
    width: 75%;
  }
  .large-10 {
    width: 83.3333333333%;
  }
  .large-11 {
    width: 91.6666666667%;
  }
  .large-12 {
    width: 100%;
  }
  .large-pull-0 {
    left: auto;
  }
  .large-pull-1 {
    left: 8.3333333333%;
  }
  .large-pull-2 {
    left: 16.6666666667%;
  }
  .large-pull-3 {
    left: 25%;
  }
  .large-pull-4 {
    left: 33.3333333333%;
  }
  .large-pull-5 {
    left: 41.6666666667%;
  }
  .large-pull-6 {
    left: 50%;
  }
  .large-pull-7 {
    left: 58.3333333333%;
  }
  .large-pull-8 {
    left: 66.6666666667%;
  }
  .large-pull-9 {
    left: 75%;
  }
  .large-pull-10 {
    left: 83.3333333333%;
  }
  .large-pull-11 {
    left: 91.6666666667%;
  }
  .large-pull-12 {
    left: 100%;
  }
  .large-push-0 {
    right: auto;
  }
  .large-push-1 {
    right: 8.3333333333%;
  }
  .large-push-2 {
    right: 16.6666666667%;
  }
  .large-push-3 {
    right: 25%;
  }
  .large-push-4 {
    right: 33.3333333333%;
  }
  .large-push-5 {
    right: 41.6666666667%;
  }
  .large-push-6 {
    right: 50%;
  }
  .large-push-7 {
    right: 58.3333333333%;
  }
  .large-push-8 {
    right: 66.6666666667%;
  }
  .large-push-9 {
    right: 75%;
  }
  .large-push-10 {
    right: 83.3333333333%;
  }
  .large-push-11 {
    right: 91.6666666667%;
  }
  .large-push-12 {
    right: 100%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-offset-12 {
    margin-left: 100%;
  }
}

.visible-tiny,
.visible-small,
.visible-medium,
.visible-large {
  display: none !important;
}

.visible-tiny-block,
.visible-tiny-inline,
.visible-tiny-inline-block,
.visible-small-block,
.visible-small-inline,
.visible-small-inline-block,
.visible-medium-block,
.visible-medium-inline,
.visible-medium-inline-block,
.visible-large-block,
.visible-large-inline,
.visible-large-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-tiny {
    display: block !important;
  }
  table.visible-tiny {
    display: table !important;
  }
  tr.visible-tiny {
    display: table-row !important;
  }
  th.visible-tiny,
  td.visible-tiny {
    display: table-cell !important;
  }
  .visible-tiny-block {
    display: block !important;
  }
  .visible-tiny-inline {
    display: inline !important;
  }
  .visible-tiny-inline-block {
    display: inline-block !important;
  }
  .hidden-tiny {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-small {
    display: block !important;
  }
  table.visible-small {
    display: table !important;
  }
  tr.visible-small {
    display: table-row !important;
  }
  th.visible-small,
  td.visible-small {
    display: table-cell !important;
  }
  .visible-small-block {
    display: block !important;
  }
  .visible-small-inline {
    display: inline !important;
  }
  .visible-small-inline-block {
    display: inline-block !important;
  }
  .hidden-small {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-medium {
    display: block !important;
  }
  table.visible-medium {
    display: table !important;
  }
  tr.visible-medium {
    display: table-row !important;
  }
  th.visible-medium,
  td.visible-medium {
    display: table-cell !important;
  }
  .visible-medium-block {
    display: block !important;
  }
  .visible-medium-inline {
    display: inline !important;
  }
  .visible-medium-inline-block {
    display: inline-block !important;
  }
  .hidden-medium {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .visible-large {
    display: block !important;
  }
  table.visible-large {
    display: table !important;
  }
  tr.visible-large {
    display: table-row !important;
  }
  th.visible-large,
  td.visible-large {
    display: table-cell !important;
  }
  .visible-large-block {
    display: block !important;
  }
  .visible-large-inline {
    display: inline !important;
  }
  .visible-large-inline-block {
    display: inline-block !important;
  }
  .hidden-large {
    display: none !important;
  }
}

.visible-print,
.visible-print-block,
.visible-print-inline,
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
  .visible-print-block {
    display: block !important;
  }
  .visible-print-inline {
    display: inline !important;
  }
  .visible-print-inline-block {
    display: inline-block !important;
  }
  .hidden-print {
    display: none !important;
  }
}

html {
  min-height: 100%;
}

body {
  overflow: hidden;
  min-height: 100%;
  background: white;
  font: 14px "Raleway", sans-serif;
  line-height: 1.2;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  text-rendering: geometricPrecision;
  position: relative;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 900;
}

h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}

h3 {
  color: black;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 15px;
}

h4 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}

h4 strong {
  font-weight: 900;
}

h6 {
  font-weight: 900;
  font-size: 14px;
}

p {
  color: #4A4A4A;
  font-size: 14px;
  line-height: 1.6;
}

p.intro {
  font-size: 16px;
  color: #9B9B9B;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
}

p.intro strong {
  color: black;
  font-weight: 500;
}

#homepage-index p.intro strong {
  font-weight: 700;
}

hr {
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: 20px 0;
}

.cursor {
  opacity: 1;
  animation: blink 1.25s infinite;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: rgba(95, 166, 252, 0.8);
  margin-right: -26px;
  margin-left: 5px;
  margin-top: -4px;
  vertical-align: middle;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .item {
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .block-calltoaction .item {
    margin-bottom: 0;
  }
}

.table {
  line-height: 1.8;
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #dedede;
}

.table.table-small {
  font-size: 12px;
  line-height: 1.3;
}

.table a {
  color: #147cfb !important;
}

.table strong {
  font-weight: 800;
}

.table tr {
  border-top: 1px solid #dedede;
}

.table th, .table td {
  padding: 15px 0;
  text-align: left;
  position: relative;
}

.table.table-small th, .table.table-small td {
  padding: 9px 0;
}

.table th {
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
}

.table.table-stripped tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.table.table-stripped tr:nth-child(even) {
  background-color: #fff;
}

.table.table-stripped th, .table.table-stripped td {
  padding: 15px;
}

.table.table-stripped.table-small th, .table.table-stripped.table-small td {
  padding: 9px;
}

.table td:first-child {
  padding-left: 25px;
}

.table thead tr {
  font-weight: 800;
  text-transform: uppercase;
  background: white !important;
}

.table tfoot tr {
  border-top: 1px solid #ddd;
  background: white !important;
}

.table i {
  margin-right: 5px;
  color: #5FA6FC;
}

.table .job-created-at i {
  display: none;
}

@media (max-width: 768px) {
  .table {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .table thead th {
    display: none;
  }
  .table td, .table th {
    display: block;
    padding: 2px 15px !important;
  }
  .table.table-small th, .table.table-small td {
    padding: 6px 5px !important;
  }
  .table td:first-child {
    margin-top: 15px;
  }
  .table td:nth-child(3) {
    margin-bottom: 15px;
  }
  .table .table-br, .table .table-br:before, .table .table-br:after {
    content: ' ';
  }
  .table .table-br:before {
    display: inline;
    content: ' — ';
  }
  .table .table-action {
    display: none;
  }
  .table .job-created-at i {
    display: inline-block;
  }
}

.table-action {
  vertical-align: middle;
}

.table-action i {
  font-size: 32px;
  color: #5FA6FC;
}

.table-action a {
  display: inline-block;
}

.table-bar {
  width: 8px;
  left: 0;
  top: 0;
  bottom: 0;
  border: 2px solid white;
  background: #ddd;
  position: absolute;
}

@media (max-width: 768px) {
  .table-bar {
    display: none;
  }
}

.table-status-closed .table-bar {
  background: #D81B60;
}

.table-status-won .table-bar {
  background: #C0CA33;
}

.table-status-open .table-bar {
  background: #FFD801;
}

.table-noresult {
  text-align: center;
  padding: 150px 50px;
  width: 100%;
}

.table-color {
  width: 1px;
  padding: 0;
  background: #FFD801;
}

.table-actions {
  text-align: center !important;
  width: 85px;
  white-space: nowrap;
}

.table-actions i {
  color: #999;
}

.table-actions i:hover {
  color: #CFE6FF;
}

.table-actions i.important:hover {
  color: #D81B60;
}

.text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
  text-align: left;
  margin-bottom: 10px;
  text-transform: none;
  font-weight: 900;
  line-height: 1.3;
}

.text h1:not(:first-child), .text h2:not(:first-child), .text h3:not(:first-child), .text h4:not(:first-child), .text h5:not(:first-child), .text h6:not(:first-child) {
  margin-top: 25px;
}

.text h2, .text h3 {
  text-transform: uppercase;
}

.text p, .text ul, .text ol, .text dl {
  line-height: 1.7;
  font-size: 14px;
  margin-bottom: 15px;
}

.text li {
  margin-left: 20px;
}

.text ul li {
  list-style: square;
}

.text ol li {
  list-style: decimal !important;
}

.text a {
  color: #5FA6FC;
}

:root {
  --rotation-angle: 15deg;
}

.no-display {
  display: none !important;
}

.error404-image {
  position: relative;
  width: 100%;
  z-index: 100;
  margin: -150px 0 -200px;
}

@media (min-width: 768px) {
  .error404-image {
    width: 500px;
  }
}

.title-link {
  position: absolute;
  bottom: 4px;
  right: 0;
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
}

.title-link i {
  font-size: 14px;
  vertical-align: top;
}

.stabilo {
  background: #FFD801;
  padding: 1px 8px;
  color: #111;
  font-weight: 800;
  white-space: nowrap;
  text-transform: uppercase;
  display: inline-block;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

*:focus {
  outline: none;
}

#please-wait {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
}

#please-wait p {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: auto;
  width: 250px;
  height: 80px;
  background-color: white;
  border: 1px solid black;
}

#please-wait p span {
  margin: auto;
  font-size: 1.3rem;
  user-select: none;
}

.blue-font {
  color: #5FA6FC !important;
}

#private-dashboard div.checkbox label {
  margin-top: 30px !important;
  line-height: 1.3rem;
  font-size: 0.9rem;
}

#private-dashboard div.checkbox label span {
  text-transform: none;
  color: lightgrey;
}

#private-dashboard div.checkbox input[type=checkbox] {
  transform: scale(1.25);
  margin-top: 10px;
}

#block-process div {
  background-color: #EEFCFF;
}

#block-process h3 {
  font-size: 16px;
}

#block-process p {
  font-size: 0.85rem;
}

#block-process p.intro {
  color: black;
}

#block-process .stabilo {
  white-space: normal;
}

#block-process .container > div {
  padding: 30px 5px;
  margin-left: auto;
  margin-right: auto;
}

#block-process .container, #block-process #block2, #block-process #block3, #block-process .arrow, #block-process .arrow div {
  background-color: transparent;
}

#block-process svg {
  margin-bottom: 15px;
}

#block-process .arrow {
  clear: both;
}

#block-process .arrow svg {
  height: 30px;
  margin-bottom: 0;
}

#block-process .material-icons {
  font-size: 2.2rem;
  color: #5FA6FC;
}

#block-process #block1 h3, #block-process #block2 h3 {
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0.15rem;
  color: #5FA6FC;
}

#block-process #block2 i {
  clear: both;
  display: block;
}

#block-process #block3a, #block-process #block3b {
  margin: 0 10px;
  padding: 30px 15px;
  width: 50%;
}

#block-process .flex-container {
  display: flex;
}

#block-process .stabilo {
  text-transform: none;
}

#block-process h3.stabilo {
  margin-bottom: 30px;
}

#block-process .container h2 {
  font-size: 1.2rem;
}

#block-process .container h2 .stabilo {
  text-transform: uppercase;
}

#block-process .button {
  margin-top: 20px;
}

#block-process .button-stabilo {
  border: none;
  background: #FFD801;
  color: black;
}

#block-process .block-title {
  width: 100%;
}

#block-process .block-title h3 {
  margin-left: auto;
  margin-right: auto;
}

#block-process #arrow-border {
  width: 49%;
  height: 35px;
  position: absolute;
  bottom: -48px;
  right: 1%;
  background: transparent;
  border-left: 1px solid #5FA6FC;
  border-bottom: 1px solid #5FA6FC;
}

#block-process #block1 svg {
  height: 70px;
}

#block-process #block2 > svg {
  height: 80px;
}

#block-process #block3 {
  padding: 30px 0 0 0;
}

#block-process #block3 svg {
  height: 40px;
}

#block-process #block4 {
  width: 70%;
}

#block-process #block4 svg {
  height: 45px;
}

#block-process #block5 svg {
  height: 50px;
}

#block-process #block5 #block5a, #block-process #block5 #block5b, #block-process #block5 #block5c {
  padding-left: 8px;
  padding-right: 8px;
}

#block-process #block6 {
  width: 80%;
}

#block-process #block6 svg {
  height: 60px;
}

#block-process #block7 svg, #block-process #block8 svg, #block-process #block10 svg {
  height: 60px;
}

#block-process #block9 {
  width: 70%;
}

#block-process #block9 svg {
  height: 60px;
}

#block-process #block11 {
  width: 80%;
}

#block-process #block11 svg {
  height: 40px;
}

#contact-index h2 {
  margin-bottom: 40px;
}

#contact-confirm .confirm-text {
  text-align: center;
}

#contact-confirm .confirm-text p {
  font-size: 1rem;
  font-weight: bold;
  color: #5FA6FC;
  margin-top: 20px;
  margin-bottom: 40px;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 10px 0 10px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  #block-process .container > div {
    padding: 30px;
  }
  #block-process h3 {
    font-size: 18px;
  }
  #block-process p {
    font-size: 1rem;
  }
  #block-process .stabilo {
    white-space: nowrap;
  }
  #block-process #block3a, #block-process #block3b {
    margin: 0 15px;
    padding: 30px;
  }
  #block-process #block5a, #block-process #block5b, #block-process #block5c {
    padding-left: 15px;
    padding-right: 15px;
  }
}

form {
  line-height: 1.3;
}

.centered form {
  margin: 0 auto;
  max-width: 50%;
}

input,
textarea,
select {
  appearance: none;
  padding: 12px 15px;
  background: #fcfcfc;
  width: 100%;
  border: 1px solid #5FA6FC;
  border-radius: 5px;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  border: 1px solid #000;
  width: auto;
  margin-right: 5px;
}

input[type="radio"] {
  appearance: radio;
}

input[type="checkbox"] {
  appearance: checkbox;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

select {
  border-radius: 0;
  height: 40px;
  padding: 0;
  padding-left: 30px;
  background-image: url(../img/select.png);
  background-repeat: no-repeat;
  background-position: left 5px top 50%;
  background-size: 20px;
}

label {
  font-weight: bold;
  font-size: 13px;
  display: block;
  letter-spacing: 0.03em;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.form-block {
  padding: 10px 0;
  border-radius: 3px;
  margin: 15px -5px;
  border: 1px solid #b6d9ff;
}

.form-row {
  padding: 15px 0;
}

.form-actions {
  padding: 15px 0;
  text-align: center;
}

@media (min-width: 768px) {
  .form-actions {
    text-align: right;
  }
}

.form-error {
  border: 3px solid #D81B60;
}

.form-errors {
  margin-top: 8px;
  color: #D81B60;
}

.form-errors li {
  font-size: 12px;
}

.form-errors li::first-letter {
  text-transform: uppercase;
}

.form-hint {
  font-size: 11px;
  margin-top: 8px;
}

.form-checkboxes,
.form-checkboxes-inline {
  margin-top: 10px;
}

.form-checkboxes label,
.form-checkboxes-inline label {
  font-weight: normal;
  text-transform: none;
}

.form-checkboxes-inline label {
  display: inline-block;
  margin-right: px;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://www.google.com/maps"] {
  display: none !important;
}

.material-icons {
  vertical-align: middle;
}

.fr-view {
  padding: 0;
}

.fr-view p, .fr-view ul, .fr-view ol {
  line-height: 1.7;
}

.fr-view h1, .fr-view h2, .fr-view h3, .fr-view h4 {
  text-transform: uppercase;
}

.fr-view ul, .fr-view ol {
  padding-left: 20px;
}

.fr-view em {
  font-style: italic;
}

.fr-view .fr-video {
  position: relative;
  padding-bottom: 56%;
  padding-top: 5px;
  height: 0;
}

.fr-view .fr-video iframe {
  background: #000;
  border: 8px solid white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fr-view .picture-large {
  width: 100% !important;
  margin: 20px 0 !important;
  height: auto;
  background: #eee;
  min-height: 30px;
}

.fr-view .picture-large:first-child {
  margin-top: 0;
}

.fr-view .picture-shadow {
  background: #eee;
  min-height: 30px;
  border: 10px solid white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fr-view .fr-fil {
  margin-right: 15px !important;
}

.fr-view .fr-fir {
  margin-left: 15px !important;
}

.fr-view .fr-dib {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.columns {
  line-height: 1.5;
}

@media (min-width: 768px) {
  .columns {
    column-count: 2;
    column-gap: 100px;
    column-rule: 1px dotted #CFE6FF;
  }
}

.column {
  break-inside: avoid-column;
}

.block {
  padding: 50px 0 50px;
  position: relative;
}

.block p:not(.intro):last-child {
  margin-bottom: 0;
}

.block-default {
  background: #fff;
}

.block-default a {
  color: #5FA6FC;
}

.block-text h1, .block-text h2, .block-text h3, .block-text h4, .block-text h5, .block-text h6, .block-text p {
  text-align: left;
}

.block-text p, .block-text ul, .block-text blockquote {
  font-size: 16px;
  margin-bottom: 20px;
}

.block-strong {
  background: #392A1F url(/videos/video.jpg);
  background-size: cover;
  color: white;
}

.block-strong h2, .block-strong h3, .block-strong h4, .block-strong p {
  color: white;
}

.block-strong p {
  font-size: 13px !important;
}

.block-strong i {
  font-size: 50px;
  margin-bottom: 5px;
  color: #e9f3ff;
}

.block-strong .block-overlay {
  background: rgba(12, 78, 153, 0.8);
}

.block-strong .block-hover {
  outline: none;
  display: block;
}

.block-strong .block-hover:hover {
  background: #FFD801;
}

.block-strong .block-hover:hover i {
  color: black;
}

.block-strong .block-hover:hover h3, .block-strong .block-hover:hover p {
  color: black !important;
}

.block-light1 {
  background: #f3f8ff;
}

.block-light2 {
  background: #f5faff;
}

.block-light3 {
  background: #f6faff;
}

.block-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.block-overlay + div {
  position: relative;
  z-index: 1;
}

.block-overlay + div.search-form {
  z-index: 9;
  user-select: none;
}

.block-overlay + div.splash-search {
  user-select: auto;
}

.block-map .logo {
  margin-bottom: 10px;
}

.block-map address {
  border-radius: 10px;
  padding: 20px 40px;
  background: white;
  font-size: 14px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  margin-top: -20px;
  width: 90%;
}

@media (min-width: 768px) {
  .block-map address {
    margin-top: -50px;
    width: auto;
  }
  .block-map .map {
    border: 5px solid white;
  }
  .block-map .row {
    padding: 0 30px;
  }
}

.block-splash {
  position: relative;
  background: black url(/videos/video.jpg) top center;
  background-size: cover;
  color: white;
}

.block-splash::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,100 2800,100 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.block-splash .block-overlay {
  background: rgba(0, 0, 0, 0.6);
}

.block-splash + .block {
  padding-top: 10px;
}

.block-enum svg {
  height: 60px;
  margin-bottom: 20px;
}

.block-enum.block-enum-icons svg {
  height: 80px;
  margin-bottom: 10px;
}

.block-enum h3 {
  white-space: nowrap;
}

.block-enum .item p {
  margin: 0;
}

.block-enum .item svg {
  transform: scale(1);
}

.block-compact {
  padding: 40px;
}

.block-compact .message,
.block-compact .form-row,
.block-compact .intro {
  margin-bottom: 20px;
}

.block-compact .form-row {
  padding: 5px;
}

.block-calltoaction {
  padding: 20px 0;
}

.block-calltoaction h3 {
  white-space: nowrap;
}

.block-calltoaction p {
  font-size: 13px;
}

.block-calltoaction a {
  padding: 35px 30px;
  display: block;
  margin: 0 -15px;
  display: block;
  height: 100%;
  border-radius: 5px;
}

.block-calltoaction .touch-device .item:hover,
.block-calltoaction .item:active {
  background: #FFD801;
  cursor: pointer;
}

.block-calltoaction .touch-device .item:hover h2, .block-calltoaction .touch-device .item:hover h3, .block-calltoaction .touch-device .item:hover h4, .block-calltoaction .touch-device .item:hover p,
.block-calltoaction .item:active h2,
.block-calltoaction .item:active h3,
.block-calltoaction .item:active h4,
.block-calltoaction .item:active p {
  color: black;
}

.block-calltoaction .touch-device .item:hover svg g,
.block-calltoaction .item:active svg g {
  fill: #111;
}

@media (min-width: 768px) {
  .block-calltoaction .row {
    display: flex;
  }
}

@media (max-width: 960px) {
  .topbar-minor {
    display: none;
  }
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  margin-bottom: -100px;
  z-index: 1000;
  text-transform: uppercase;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: white;
  text-rendering: geometricPrecision;
  padding-right: 15px;
  backface-visibility: hidden;
}

.not-touch-device .topbar {
  transition: all 0.2s;
}

.topbar-container {
  height: 65px;
  display: block;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.topbar-logo {
  padding: 12px 15px;
  display: block;
  overflow: hidden;
}

.topbar-logo svg {
  display: inline-block;
  width: 185px;
  height: 40px;
  transition: all 0.2s;
  transform-origin: left center;
}

@media (min-width: 992px) {
  .topbar-logo svg {
    transform: scale(1.25);
  }
}

.topbar-logo svg #job, .topbar-logo svg #opportun, .topbar-logo svg #it {
  fill: white;
  transition: all 0.2s;
}

.topbar-primary {
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  opacity: 1;
}

.topbar-primary svg {
  transform: scale(1);
}

.topbar-primary svg #opportun {
  fill: white;
  opacity: .8;
}

.topbar-secondary {
  background: linear-gradient(#f7f7f7, rgba(255, 255, 255, 0.95));
  border-bottom: 1px solid lightgray;
  color: black;
}

.topbar-secondary svg {
  transform: scale(1);
}

.topbar-secondary svg #job, .topbar-secondary svg #it {
  fill: #5FA6FC;
}

.topbar-secondary svg #opportun {
  fill: black;
}

.topbar-secondary .navigation-button {
  border-color: #5FA6FC !important;
  color: #5FA6FC;
  cursor: pointer;
  border: 4px solid #000;
}

.site-menu-opened .navigation {
  color: black;
}

/* MENU ON SMALL SCREEN */
/* Configuration Parameters */
.nav {
  max-height: 0;
  overflow: hidden;
  margin-left: -40px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
}

.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav li.active > label, .nav li.active > label > a, .nav li.active > a {
  background: #5FA6FC !important;
}

.nav input[type=checkbox] {
  display: none;
}

.nav__list {
  background: #fff;
}

#hamburger-checkbox {
  display: none;
}

.hamburger-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
  cursor: pointer;
  height: 19px;
  width: 25px;
}

.hamburger-icon span {
  display: block;
  top: 8px;
  width: 25px;
  height: 3px;
  background-color: #fff;
  position: relative;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger-icon span:after, .hamburger-icon span:before {
  display: block;
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #fff;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger-icon span:before {
  margin-top: -8px;
}

.hamburger-icon span:after {
  margin-top: 8px;
}

#hamburger-checkbox:checked ~ .nav {
  width: 250px;
  max-height: calc(100vh - 67px);
  overflow: scroll;
  -webkit-transition: max-height 0.5s;
}

#hamburger-checkbox:checked ~ .hamburger-icon span {
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

#hamburger-checkbox:checked ~ .hamburger-icon span:before, #hamburger-checkbox:checked ~ .hamburger-icon span:after {
  margin-top: 0px;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

#hamburger-checkbox:checked ~ .hamburger-icon span:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-checkbox:checked ~ .hamburger-icon span:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav .label-link {
  padding: 0;
}

.nav a, .nav label {
  display: block;
  padding: .85rem;
  color: #000;
  background-color: #fff;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.nav a:focus, .nav a:hover, .nav label:focus, .nav label:hover {
  color: #030303;
  background: rgba(255, 255, 255, 0.5);
}

.nav label {
  margin-bottom: 0;
  border-bottom: 1px solid #bbb;
  cursor: pointer;
}

/**
 * Styling first level lists items
 */
.group-list a, .group-list label {
  padding-left: 2rem;
  background: rgba(128, 128, 128, 0.3) !important;
  border-bottom: 1px solid #bbb;
}

.group-list a:focus, .group-list a:hover, .group-list label:focus, .group-list label:hover {
  background: #999;
}

/**
 * Hide nested lists
 */
.group-list {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .2s ease-in-out;
}

.nav__list input[type=checkbox]:checked + label + ul {
  /* reset the height when checkbox is checked */
  max-height: 1000px;
}

.nav label > span {
  float: right;
  -webkit-transition: -webkit-transform .2s;
  transition: transform .2s;
}

.nav__list input[type=checkbox]:checked + label > span {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* MENU ON LARGE SCREEN */
.button-toggle-menu {
  font-size: 28px;
  position: absolute;
  top: 13px;
  left: 15px;
  transition: all 0.2s;
  display: none;
}

.button-toggle-menu:after {
  content: "☰";
  display: block;
  text-rendering: optimizeLegibility;
}

.navigation {
  text-align: left;
}

.navigation ul, .navigation li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation .navigation-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 768px) {
  .navigation .navigation-menu {
    flex-flow: column wrap;
  }
}

.has-children {
  position: relative;
}

.has-children:hover > .sous-menu {
  display: flex !important;
}

.sous-menu {
  display: none;
  position: absolute;
  flex-flow: column wrap;
  min-width: 100px;
  max-height: 250px;
  margin-top: 45px;
  margin-left: 13%;
  line-height: 2em;
  background: white;
  -webkit-box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}

@media (max-width: 768px) {
  .sous-menu {
    position: static;
    border: none;
    background: rgba(128, 128, 128, 0.5);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
}

.sous-menu.is-active {
  display: flex !important;
}

.sous-menu a {
  text-align: left;
  color: black;
}

@media (max-width: 768px) {
  .sous-menu a {
    padding-left: 30px !important;
  }
}

.sous-menu .sous-menu {
  top: 0px;
  left: 100%;
  background: #1e76d6;
}

.site-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s;
  z-index: 50;
}

.site-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  transition: all 0.2s ease-out;
  backface-visibility: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.site-menu-opened .site-container {
  overflow: initial;
}

.site-body {
  height: 100%;
  backface-visibility: hidden;
  overflow: auto;
  overflow: initial;
}

@media (max-width: 768px) {
  .hide-on-small {
    display: none;
  }
  .hide-on-large {
    display: block;
  }
  .topbar {
    position: sticky;
  }
  .topbar-logo {
    text-align: center;
    margin-left: -20px;
  }
  .button-toggle-menu {
    display: block;
    text-decoration: none;
    transform: rotate(0deg);
    z-index: 1010;
  }
  .topbar-container {
    padding-left: 40px;
  }
  .navigation {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    transform: translateX(-100%);
    overflow-y: auto;
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.2);
  }
  .navigation ul {
    border-top: 1px solid #eee;
  }
  .navigation li {
    border-bottom: 1px solid #ddd;
    line-height: normal;
  }
  .navigation li a, .navigation li span {
    padding: 15px;
    color: black !important;
    display: block;
  }
  .navigation li.active > a {
    background: #5FA6FC !important;
  }
  .navigation li.active > .sous-menu > li.active > button-toggle-menu a {
    background: #5FA6FC !important;
  }
  ul.navigation-menu li {
    display: block;
  }
  ul.navigation-menu a {
    display: block;
  }
  ul.navigation-submenu {
    font-size: 13px;
    margin-left: 30px;
    border-left: 1px dotted gray;
  }
  .site-menu-opened .site-container {
    transform: translateX(250px);
  }
  .site-menu-opened .site-overlay {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
  .site-menu-opened .site-body {
    overflow-y: hidden;
  }
  .site-menu-opened .button-toggle-menu {
    transform: rotate(90deg);
    position: absolute;
  }
  .site-menu-opened .button-toggle-menu:after {
    content: "✕";
  }
}

@media (min-width: 768px) {
  .hide-on-small {
    display: block;
  }
  .hide-on-large {
    display: none;
  }
  .navigation {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navigation a, .navigation span {
    display: block;
    padding: 10px 10px;
    user-select: none;
  }
  .navigation a:hover, .navigation span:hover {
    color: grey;
  }
  .navigation li.active > a, .navigation li.active > span {
    font-weight: 900;
    color: #5FA6FC;
  }
  .navigation li.active > a:hover, .navigation li.active > span:hover {
    font-weight: 900;
    color: grey;
  }
  .navigation .sous-menu {
    padding: 15px 10px;
  }
  .navigation .sous-menu a {
    padding: 2px 10px;
    font-size: 0.7rem;
  }
  .navigation .navigation-button {
    border: 2px solid white;
    border-radius: 5px;
    margin-top: -2px;
    margin-left: 10px;
    padding-left: 6px;
    padding-right: 6px;
  }
  ul.navigation-menu {
    display: inline-block;
  }
  ul.navigation-menu li {
    float: left;
    position: relative;
  }
  ul.navigation-menu li:hover ul.navigation-submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }
  .navigation-has-submenu > a:after {
    content: "▼";
    display: inline-block;
    color: white;
    font-size: 5px;
    margin-left: 10px;
    opacity: .5;
  }
  ul.navigation-submenu {
    width: 150px;
    position: absolute;
    left: 0;
    background: #30373B;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: visibility 0s .10s, opacity .10s, transform .10s;
  }
  ul.navigation-submenu li {
    float: none;
  }
}

.splash {
  padding: 150px 0 125px;
}

.splash form {
  text-align: center;
  margin: 30px 0 0 0;
}

.splash input:-webkit-autofill,
.splash input:-webkit-autofill:hover,
.splash input:-webkit-autofill:focus,
.splash input:-webkit-autofill:active {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #444 inset;
  border-color: white;
}

.splash video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}

.touch-device .splash video {
  display: none;
}

.splash h1 {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  line-height: 1.3;
}

.splash h1 strong {
  color: #5FA6FC;
}

.splash h1 small {
  display: block;
  line-height: 1.5;
  font-size: 26px;
}

.splash h2 {
  margin: 25px 0;
  font-weight: 300;
  font-size: 2.5rem;
  font: 16px "Andale Mono", "Lucida Console", Monaco, Courier, monospace;
  line-height: 1.6;
}

.splash-buttons {
  text-align: center;
  margin-top: 35px;
  margin-bottom: -20px;
}

.splash-buttons .button-basic {
  opacity: .9;
  color: white;
  border-color: white;
}

.splash-tagline strong {
  color: #5FA6FC;
}

.splash-tagline .space {
  display: inline-block;
  width: 4px;
}

.splash-search-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.splash-search {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.08) url(/img/icons/search.png) no-repeat;
  background-position: 15px center;
  background-size: 28px 28px;
  color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  padding-left: 55px;
  padding-right: 55px;
  width: 100%;
}

.splash-search-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 4px;
  font-weight: 900;
  padding: 16px 15px 15px;
  margin-left: -100px;
  text-transform: uppercase;
  position: absolute;
  right: 5px;
  top: 6px;
  cursor: pointer;
  transition: .5s background;
  outline: 0;
  font-size: 11px;
}

.splash-search-button:hover {
  background: #5FA6FC;
}

.splash-checkboxes {
  display: block;
  margin-top: 20px;
  margin-bottom: -40px;
  color: white;
}

.splash-checkboxes label {
  margin: 5px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
}

.splash-checkboxes input {
  margin: 5px;
}

.no-touch-device .splash video {
  display: block;
}

.splash-tagline-larger-font {
  font-size: 18px;
  border-bottom: 3px double;
}

@media (min-width: 768px) {
  .splash h1 {
    font-size: 42px;
  }
  .splash h1 small {
    font-size: 35px;
  }
  .splash form {
    margin: 30px 0 0 0;
  }
  .splash-search-container {
    width: 70%;
  }
  .splash-search {
    background-position: 25px center;
    font-size: 1.2em;
    padding: 20px;
    padding-left: 70px;
  }
  .splash-search-button {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .splash-checkboxes {
    line-height: 1.7;
  }
  .splash-checkboxes label:nth-child(3):after {
    content: '';
    display: block;
  }
}

.footer {
  padding-top: 100px;
  color: white;
  position: relative;
}

.footer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 2800,0 0,100'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: 0;
}

.footer .footer-logo svg {
  width: 190px;
  margin: -5px auto 15px;
}

.footer .footer-logo svg #job, .footer .footer-logo svg #opportun, .footer .footer-logo svg #it {
  fill: white;
}

.footer address {
  padding: 0;
  margin: 0;
  line-height: 1.7;
  font-size: 11px;
  margin-bottom: 25px;
  opacity: .8;
}

.footer h4 {
  font-size: 15px;
  margin-bottom: 20px;
}

.footer p, .footer ul {
  line-height: 2;
  font-size: 13px;
  margin-bottom: 15px;
}

.footer ul {
  line-height: 1.7;
}

.footer li {
  margin-bottom: 5px;
  position: relative;
  text-align: center;
}

.footer .col {
  width: 100%;
  padding: 0 15px;
}

.footer .col-3 {
  padding-bottom: 10px;
}

.footer .col-3 span {
  margin-top: 10px;
}

.footer .col-4 {
  text-align: center;
}

.footer .col-5 {
  text-align: center;
}

.footer .footer-menu-subtitle {
  font-style: italic;
}

.footer .footer-left-border {
  border-left: none;
  padding-left: 0;
}

.footer .item:nth-child(1) {
  text-align: center;
}

.footer .block-overlay {
  background: rgba(12, 78, 153, 0.8);
}

.block-light1 + .footer {
  position: relative;
}

.block-light1 + .footer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='2800' fill='rgb(243,248,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 2800,0 0,100'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: 0;
}

.block-light2 + .footer {
  position: relative;
}

.block-light2 + .footer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='2800' fill='rgb(245,250,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 2800,0 0,100'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: 0;
}

.block-default + .footer {
  position: relative;
}

.block-default + .footer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='2800' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,0 2800,0 0,100'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 100px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: 0;
}

.footer .separator {
  background: rgba(255, 216, 1, 0.4);
  height: 1px;
  margin: 20px 0;
  display: none;
}

.footer .social {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.footer .social a {
  margin: 0;
  margin-right: 10px;
}

.footer .social h6 {
  display: none;
}

.footer .social [class^="icon-"] {
  width: 22px;
}

.footer-link {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
  display: block;
  margin-bottom: 50px;
}

.footer-bullet li {
  padding-left: 20px;
  text-align: left;
}

.footer-bullet li:before {
  position: absolute;
  left: 0;
  top: -7px;
  font-size: 19px;
  content: "■";
  color: #FFD801;
}

.footer-bullet + .footer-link {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .footer {
    padding-top: 150px;
  }
  .footer .col {
    width: 25%;
    float: right;
  }
  .footer .col-4 {
    text-align: left;
    padding-left: 15px;
  }
  .footer p {
    padding-right: 20px;
    max-width: 400px;
  }
  .footer li {
    margin-bottom: 15px;
    text-align: left;
  }
  .footer .col-1 {
    padding-right: 15px;
  }
  .footer .col-3 {
    margin-top: 50px;
  }
  .footer .col-3 li {
    margin-bottom: 8px;
  }
  .footer .col-5 {
    width: 100%;
    padding-left: 15px;
    text-align: left;
  }
  .footer h4 {
    margin-bottom: 30px;
    text-align: left;
  }
  .footer .separator {
    width: 80%;
    display: block;
  }
  .footer .item:nth-child(1) {
    text-align: left;
  }
  .footer .social {
    border: none;
    text-align: left !important;
  }
  .footer .footer-left-border {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding-left: 10px;
  }
}

.footer-copyright {
  font-size: 10px !important;
  text-transform: capitalize;
  opacity: .8;
  width: 100%;
}

.footer-copyright a {
  text-transform: uppercase;
  color: #9bc7fd;
}

.footer-legals {
  margin-top: 50px;
  opacity: .8;
}

.footer-legals .small-9 {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
}

.footer-legals li {
  margin: 0;
  font-size: 10px;
}

@media (min-width: 992px) {
  .footer .col {
    width: 20%;
  }
  .footer .col-4 {
    padding-left: 25px;
  }
}

@media (min-width: 1200px) {
  .footer p, .footer ul {
    padding-right: 40px;
  }
  .footer-link {
    margin-top: 30px !important;
  }
}

.button {
  display: inline-block;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  appearance: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
}

.button:first-child {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}

.button i {
  vertical-align: middle;
  margin-top: -3px;
  font-size: 16px;
  margin-right: 5px;
  width: 15px;
  text-align: center;
}

.button strong {
  font-weight: 800;
}

.button.disabled {
  cursor: default;
}

.button[disabled] {
  opacity: .6;
}

.button-small {
  padding: 10px 15px;
  font-size: 10px;
}

.button-basic {
  border-color: #666;
  color: #666;
}

.button-primary {
  border-color: #5FA6FC;
  color: #5FA6FC;
}

.button-fill-primary {
  border-color: #5FA6FC;
  background: #5FA6FC;
  color: white !important;
}

.button-secondary {
  border-color: #FFD801;
  color: #FFD801;
}

.button-fill-secondary {
  border-color: #FFD801;
  background: #FFD801;
  color: black !important;
}

.button-block {
  width: 100%;
  display: inline-block;
}

.button-facebook,
.button-github,
.button-linkedin {
  color: white !important;
  border: none;
  margin-bottom: 10px;
}

.button-facebook {
  background: #3B5998;
  border: 2px solid #3B5998;
}

.button-github {
  background: #333;
  border: 2px solid #333;
}

.button-linkedin {
  background: #1D87BD;
  border: 2px solid #1D87BD;
}

.button-left {
  text-align: left;
}

.buttons-horizontal {
  margin-bottom: 20px;
}

.buttons-horizontal .button {
  margin-right: 10px;
  display: inline-block;
}

.buttons-horizontal .button:last-child {
  margin-right: 0;
}

@media (max-width: 480px) {
  .buttons-horizontal .button {
    margin-right: 0px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .buttons-horizontal {
    display: flex;
  }
  .buttons-horizontal .button {
    flex: 1 1;
    margin-right: 15px;
    text-align: center;
  }
}

.buttons-vertical .button {
  display: block;
  margin-bottom: 15px;
}

.buttons-round {
  text-align: center;
}

.button-round-wrapper {
  text-align: center;
  display: inline-block;
  margin: 5px 10px;
  width: 175px;
  line-height: 1.5;
}

.button-round-wrapper .button-round {
  display: inline-block;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 50px;
  border: 2px solid transparent;
}

.button-round-wrapper .button-round i {
  font-size: 34px;
  display: inline-block;
  margin-top: -7px;
  margin-right: -1px;
  width: 35px;
}

.button-round-wrapper.brw-big {
  margin: -10px 10px 5px 10px;
  width: 300px;
}

.button-round-wrapper.brw-big .button-round {
  width: 95px;
  height: 95px;
  min-width: 95px;
  min-height: 95px;
  border: 3px solid #5FA6FC;
  outline: none;
}

.button-round-wrapper.brw-big .button-round svg {
  width: 70px;
  height: auto;
  display: inline-block;
  margin-top: 7px;
  margin-right: -1px;
}

.button-round-wrapper.brw-big .button-round:hover {
  border-color: #FFD801;
}

.button-round-wrapper.brw-big .button-round:hover svg .st0 {
  fill: #FFD801;
}

.button-round-wrapper.brw-big span {
  font-size: 1.1rem;
  font-weight: 900;
}

.button-round-wrapper .button-favorite i {
  margin-top: -1px;
}

.button-round-wrapper .button-application i {
  margin-top: -2px;
  margin-left: 0px;
}

.button-round-wrapper .button-newsletter i {
  margin-top: 0px;
}

.button-round-wrapper .button-round-basic {
  border: 2px solid #5FA6FC;
  background: white;
  color: #5FA6FC;
}

.button-round-wrapper .button-round-primary {
  background: #5FA6FC;
  color: white;
  border: 2px solid #5FA6FC;
}

.button-round-wrapper .button-round-secondary {
  background: #FFD801;
  color: black;
  box-shadow: 0 0 0 3px white;
}

.button-round-wrapper span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.button-upload {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  text-align: left;
}

.button-upload input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.reset-email-inline-button {
  display: inline-block;
}

.reset-email-inline-button button, .reset-email-inline-button a {
  height: 2rem;
  padding: 6px 10px;
  margin: 5px 0;
  white-space: nowrap;
}

.centered form.wide-form {
  max-width: 100%;
}

.circle {
  text-align: center;
  border: 2px solid #111;
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
  flex: 0 0 auto;
  background: white;
}

.circle div {
  margin-top: -5px;
}

.circle small {
  display: block;
  font-size: 10px;
  font-weight: 600;
  color: #555;
}

.circle-letter {
  text-align: center;
  border: 2px solid #111;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.circle-strong {
  letter-spacing: -5px;
  display: block;
  margin: 0 auto 15px;
  text-align: center;
  background: #5FA6FC;
  color: white;
  font-weight: 800;
  font-size: 54px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-transform: uppercase;
}

.map {
  height: 250px;
  width: 100%;
  display: inline-block;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
}

.social {
  margin-top: 40px;
  text-align: center;
}

.social a {
  display: inline-block;
  margin: 20px 10px 0;
  opacity: .5;
  transition: .25s;
}

.social a:hover {
  opacity: 1;
  transform: scale(1.2);
}

.social [class^="icon-"] {
  width: 28px;
  height: 28px;
}

.social [class^="icon-"] path {
  fill: white;
  transition: .25s;
}

.social [class^="icon-"]:hover path {
  fill: #ffe034;
}

.social-short {
  text-align: left;
  margin: 0;
  font-weight: normal;
}

.social-short p, .social-short a {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  opacity: 1;
}

.social-short [class^="icon-"] path {
  fill: #5FA6FC;
}

.timeline {
  position: relative;
}

.timeline-all {
  display: block;
  line-height: 1.4;
  z-index: 100;
  width: 50%;
  margin-top: 15px;
  padding: 0px 120px;
  position: absolute;
  right: 0;
  display: none;
}

.timeline-all:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-all-link {
  color: #4A4A4A !important;
}

.timeline-all-link strong {
  display: inline-block;
  font-weight: 800;
  margin-bottom: 5px;
  color: black;
}

.timeline-all-link i {
  float: left;
  font-size: 70px;
  margin-right: 15px;
  margin-top: -5px;
  color: #FFD801;
}

.timeline-all-link strong, .timeline-all-link i {
  transition: all .25s;
}

.timeline-all:hover strong {
  color: black;
}

.timeline-all:hover i {
  color: #5FA6FC;
}

.timeline-number {
  font-size: 54px;
  font-weight: 800;
  color: #5FA6FC;
  float: left;
  margin: -15px 0;
  margin-right: 15px;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(1) {
  transition-delay: 200ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(2) {
  transition-delay: 400ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(3) {
  transition-delay: 600ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(4) {
  transition-delay: 800ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(5) {
  transition-delay: 1000ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(6) {
  transition-delay: 1200ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(7) {
  transition-delay: 1400ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(8) {
  transition-delay: 1600ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(9) {
  transition-delay: 1800ms;
}

div:not(#homepage-index) .timeline-starting .timeline-wrapper:nth-child(10) {
  transition-delay: 2000ms;
}

.timeline-year {
  padding: 20px;
  position: relative;
  background: white;
  font-size: 40px;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  width: 150px;
  font-weight: 800;
  transform: scale(0);
  transition: 0.5s all;
}

.timeline-year.appeared {
  opacity: 1;
  transform: scale(1);
}

.timeline-past {
  margin: 50px 0;
  border-top: 8px dotted #e7eef5;
  position: relative;
}

.timeline-axe-x {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: calc(100% - 20px);
  width: 10px;
  z-index: 0;
}

.timeline-axe-x,
.timeline-axe-y {
  background: #e7eef5;
}

.timeline-wrapper {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s all;
  transition-delay: .25s;
}

.timeline-wrapper.appeared {
  opacity: 1;
  transform: translateY(0);
}

.timeline-block {
  position: relative;
  border: 1px solid #bbb;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: left;
  background: white;
  z-index: 1;
  display: inline-block;
}

.timeline-block a {
  color: inherit;
}

.timeline-block a[href="#"] {
  cursor: default;
}

.timeline-block h5 {
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 5px;
}

.timeline-block p:not(:last-child) {
  margin-bottom: 5px;
}

.timeline-block time {
  display: block;
  font-size: 13px;
  color: black;
  font-weight: 600;
}

.timeline-block-wrapper {
  display: flex;
}

.timeline-bar {
  background: #FFD801;
  width: 16px;
  margin: 3px;
}

.timeline-stat .timeline-bar {
  background: #5FA6FC;
}

.timeline-image {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  align-items: stretch;
  flex: 1;
}

.timeline-image-wrapper {
  display: block;
  width: 100%;
  min-height: 100%;
  background: white no-repeat center center;
  background-size: contain;
}

.timeline-details {
  padding: 20px;
  flex: 2;
}

.timeline-month {
  display: block;
  width: 70px;
  height: 70px;
  line-height: 62px;
  border-radius: 50%;
  border: 5px solid white;
  color: white;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin: 100px auto;
  background: #FFD801;
}

.timeline-stat .timeline-month {
  background: #5FA6FC;
}

.timeline-wrapper {
  text-align: center;
}

.timeline-wrapper .timeline-axe-y {
  right: 0;
}

.timeline-wrapper .timeline-month {
  margin: 10px auto;
}

@media (min-width: 992px) {
  .timeline-wrapper {
    margin: 0;
  }
  .timeline-axe-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 10px;
    width: 50%;
  }
  .timeline-wrapper {
    margin: -10px 0;
    text-align: left;
  }
  .timeline-block {
    width: calc(50% - 80px);
  }
  .timeline-month {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .timeline-wrapper.timeline-even {
    text-align: right;
  }
  .timeline-wrapper.timeline-even .timeline-axe-y {
    right: 0;
  }
  .timeline-wrapper.timeline-even .timeline-month {
    margin: 0;
  }
  .timeline-wrapper.timeline-odd .timeline-bar {
    order: 3;
  }
  .timeline-wrapper.timeline-odd .timeline-image {
    order: 2;
  }
  .timeline-wrapper.timeline-odd .timeline-axe-y {
    left: 0;
  }
  .timeline-wrapper.timeline-odd .timeline-month {
    margin: 0;
  }
  .timeline-all {
    display: block;
  }
}

.about-history {
  margin-bottom: 25px;
}

.history.hidden-tiny {
  display: flex;
  flex-wrap: wrap;
  padding: 100px 50px 50px 60px;
}

.history.hidden-tiny > li {
  position: relative;
  width: calc(100% / 10);
}

.history.hidden-tiny > li:nth-of-type(even) > .content {
  transform: translateY(calc(-100% - 24px));
}

.history.hidden-tiny > li:nth-of-type(even) > .content::before {
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  border: 13px solid #4698fc;
  border-top-color: #3f444c;
  border-right-color: #3f444c;
  border-bottom-color: #3f444c;
  box-sizing: border-box;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #4698fc;
  position: absolute;
  left: -17px;
  top: -20px;
}

.history.hidden-tiny > li:nth-of-type(even) > .content + .history-text-container {
  top: -172px;
}

.history.hidden-tiny > li:nth-of-type(even) > .content + .history-text-container .history-text {
  position: absolute;
  bottom: 0;
}

.history.hidden-tiny > li:nth-of-type(odd) > .content::before {
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  border: 13px solid #4698fc;
  border-top-color: #3f444c;
  border-right-color: #3f444c;
  border-bottom-color: #3f444c;
  box-sizing: border-box;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #4698fc;
  border-bottom-color: transparent;
  position: absolute;
  left: -17px;
  top: 88px;
}

.history.hidden-tiny > li:nth-of-type(odd) > .content + .history-text-container {
  top: 112px;
}

.history.hidden-tiny > li .month-label {
  position: absolute;
  height: 80px;
  left: -24px;
  z-index: 99;
  color: white;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul::after {
  content: '';
  display: block;
  clear: both;
}

ul .year {
  color: #4698fc;
  background: #3f444c;
  display: block;
  position: relative;
  padding-left: 24px;
  line-height: 18px;
  font-weight: bold;
  font-size: 18px;
}

ul .year.last-item {
  background: linear-gradient(0.25turn, #3f444c, #fff);
}

ul .year::before {
  content: '';
  width: 60px;
  height: 60px;
  position: absolute;
  background: #4698fc;
  border-radius: 50%;
  left: -33px;
  top: calc( (60 - 18) / 2 * -1px);
}

ul .year.new {
  border-radius: 0 24px 24px 0;
}

ul .year.new::after {
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  border: 20px solid #3f444c;
  box-sizing: border-box;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  right: -30px;
  top: -9.5px;
}

ul .content {
  height: 70px;
  border-left: 8px solid #4698fc;
  margin-left: -8px;
  padding: 15px 0;
  padding-right: 24px;
}

ul .content > li {
  padding-left: 20px;
  border-bottom: 4px solid #eef4f7;
  padding-bottom: 8px;
  position: relative;
  margin-left: -8px;
}

ul .content > li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

ul .content > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #eef4f7;
  left: 2px;
  top: 8px;
}

ul .content::before {
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  border: 13px solid #4698fc;
  border-top-color: #3f444c;
  border-right-color: #3f444c;
  border-bottom-color: #3f444c;
  box-sizing: border-box;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #4698fc;
  position: absolute;
  left: -17px;
  top: -20px;
}

.history-text-container {
  position: absolute;
  width: 120px;
  height: 80px;
  left: -65px;
}

.history-text-container .history-text {
  color: black;
  text-align: center;
  font-size: 1rem;
}

ul.history.visible-tiny {
  display: block;
  margin: 40px 40px 0 40px;
}

ul.history.visible-tiny > li {
  width: 100%;
}

ul.history.visible-tiny > li:nth-of-type(even) .content {
  transform: none;
}

ul.history.visible-tiny .year {
  color: white;
  background: #3f444c;
  display: block;
  position: relative;
  padding-left: 24px;
  line-height: 24px;
  font-weight: bold;
  font-size: 16px;
}

ul.history.visible-tiny .year::before {
  content: '';
  width: 40px;
  height: 40px;
  position: absolute;
  background: #4698fc;
  border-radius: 50%;
  left: -24px;
  top: calc( (40 - 24) / 2 * -1px);
}

ul.history.visible-tiny .year.new {
  border-radius: 0 24px 24px 0;
}

ul.history.visible-tiny .year.new::after {
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  border: 20px solid #3f444c;
  box-sizing: border-box;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  right: -30px;
  top: -9.5px;
}

ul.history.visible-tiny .content {
  border-left: 8px solid #4698fc;
  margin-left: -8px;
  padding: 15px 0;
  padding-right: 24px;
}

ul.history.visible-tiny .content > li {
  padding-left: 20px;
  border-bottom: 4px solid #eef4f7;
  padding-bottom: 8px;
  position: relative;
  margin-left: -8px;
}

ul.history.visible-tiny .content > li:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

ul.history.visible-tiny .content > li::before {
  display: none;
}

ul.history.visible-tiny .content::before {
  display: none;
}

@media screen and (max-width: 1140px) {
  ul.history > li {
    width: calc( 100% / 10);
  }
}

@media screen and (max-width: 991px) {
  ul.history > li {
    width: calc( 100% / 10);
  }
  ul.history > li .month-label {
    left: -21px;
    font-size: 0.8rem;
  }
  ul .year::before {
    width: 50px;
    height: 50px;
    left: -28px;
    top: calc( (50 - 18) / 2 * -1px);
  }
}

.about-inside-jobopp .img-line-container {
  margin-top: 50px;
}

.about-inside-jobopp .img-line-container div {
  text-align: center;
}

.about-inside-jobopp .img-line-container img {
  width: 100%;
  vertical-align: middle;
  transform: rotate(var(--rotation-angle));
}

@media (min-width: 768px) {
  .about-inside-jobopp .img-line-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .about-inside-jobopp .img-line-container div {
    display: inline;
    margin-bottom: -40px;
  }
}

@media (min-width: 1200px) {
  #inside-larger-img {
    width: 150%;
    margin-left: -60px;
  }
}

.youtube {
  text-align: center;
  margin-top: -90px;
  margin-bottom: -60px;
  position: relative;
  z-index: 10;
}

.youtube iframe {
  background: #000;
  border: 5px solid white;
}

@media (min-width: 768px) {
  .youtube iframe {
    width: 600px;
    height: 340px;
  }
}

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23), 0 0px 10px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.map {
  height: 250px;
  width: 100%;
  display: inline-block;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
}

#jobs-count-animation {
  color: #5FA6FC;
  font-weight: bold;
}

#job-pictures-container {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

.search-reset-link {
  width: 100%;
  z-index: 9;
  text-align: right;
  display: inline-block;
  position: relative;
  font-size: 0.7rem;
  color: #5FA6FC;
  text-decoration: underline;
}

.job-highlights {
  text-align: left;
  margin: 25px 0 10px 0;
  font-size: 0.8rem;
}

.job-highlights ul {
  display: inline-block;
  text-align: center;
}

.job-highlights li {
  display: inline-block;
  cursor: pointer;
  padding: 7px 10px 5px 10px;
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  margin-top: 10px;
}

.job-highlights li a {
  display: block;
  padding: 2px 0;
  color: white;
}

#homepage-index .job-highlights {
  top: -5px;
  margin: 10px 0;
  padding-left: 0;
  text-align: center;
}

.job-selector-lists {
  text-align: left;
  font-size: 0.8rem;
  margin-top: 30px;
}

.job-selector-lists .job-selector-flex-container {
  text-align: left;
}

.job-selector-lists strong {
  float: left;
}

.job-selector-lists p {
  color: white;
}

.job-selector-lists .scrolling-list-container {
  display: inline-block;
  position: relative;
  padding: 35px 15px 20px 15px;
  width: auto;
  height: auto;
  max-height: 250px;
  margin-top: 31px;
  line-height: 2em;
  background: white;
  -webkit-box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}

.job-selector-lists .scrolling-list-container.inline {
  display: inline-block !important;
}

.job-selector-lists .close-button {
  position: absolute;
  right: 14px;
  top: 7px;
  width: 22px;
  height: 22px;
  opacity: 0.3;
}

.job-selector-lists .close-button:hover {
  opacity: 1;
}

.job-selector-lists .close-button:before, .job-selector-lists .close-button:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 23px;
  width: 2px;
  background-color: #333;
}

.job-selector-lists .close-button:before {
  transform: rotate(45deg);
}

.job-selector-lists .close-button:after {
  transform: rotate(-45deg);
}

.job-selector-lists .job-select-fields-label {
  margin-right: 34px;
  white-space: nowrap;
}

.job-selector-lists .twobar {
  white-space: nowrap;
}

.job-selector-lists ul {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  margin: auto;
  padding-right: 15px;
  scrollbar-color: #706F6F #EDEDED;
  scrollbar-width: thin;
}

.job-selector-lists ul::-webkit-scrollbar {
  width: 9px;
}

.job-selector-lists ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.job-selector-lists ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px black;
}

.job-selector-lists ul li {
  position: relative;
  margin-bottom: 15px;
  white-space: nowrap;
  text-align: left;
}

.job-selector-lists ul li label {
  display: inline-block;
  margin-bottom: 0;
  text-align: left;
  text-transform: none;
  color: black;
  font-weight: bold;
  font-size: 0.8rem;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
}

.job-selector-lists ul input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -border-radius: 2px;
  height: 19px;
  width: 19px;
  padding: 0;
  margin-right: 10px;
  background: #E1E1E1;
  border: none;
  cursor: pointer;
  vertical-align: middle;
}

.job-selector-lists ul input[type=checkbox]:checked {
  background: #5FA6FC;
  margin-right: 10px;
}

.job-selector-lists ul input[type=checkbox]:focus {
  outline: none;
}

.job-selector-lists ul input[type=checkbox]:checked + label:after {
  content: "";
  background-color: transparent;
  /* position the checkbox */
  position: absolute;
  left: 6px;
  top: 5px;
  /* setting the checkbox */
  /* short arm */
  width: 7px;
  border-bottom: 1.5px solid white;
  /* long arm */
  height: 14px;
  border-right: 1.5px solid white;
  /* rotate the mirrored L to make it a checkbox */
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#professions-selector {
  width: 100%;
  min-width: 193px;
  min-height: 30px;
  float: left;
  text-align: center;
}

#professions-selector .selector-container {
  float: right;
}

#professions-selector .scrolling-list-container label {
  width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#professions-selector .twobar {
  margin-left: calc((100vw - 193px) / 2 - 13px);
}

#types-selector {
  width: 100%;
  min-width: 180px;
  min-height: 30px;
  float: left;
  margin-top: 20px;
  text-align: center;
}

#types-selector .selector-container {
  float: left;
}

#types-selector .twobar {
  margin-left: calc((100vw - 180px) / 2 - 15px);
}

#types-selector li:last-of-type {
  margin-bottom: 0;
}

#work-modes-selector {
  width: 100%;
  min-width: 174px;
  min-height: 30px;
  float: left;
  margin-top: 20px;
  text-align: center;
}

#work-modes-selector .selector-container {
  float: left;
}

#work-modes-selector .twobar {
  margin-left: calc((100vw - 174px) / 2 - 12px);
}

#work-modes-selector li:last-of-type {
  margin-bottom: 0;
}

.selector-container {
  width: 100%;
}

.twobar {
  cursor: pointer;
  position: absolute;
  height: 30px;
  float: left;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding-left: 15px;
  padding-top: 3px;
  font-size: 0.9rem;
  text-align: left;
  background-color: none;
}

.twobar #selected-prof-count, .twobar #selected-types-count, .twobar #selected-work-modes-count {
  color: #5FA6FC;
  font-size: 1rem;
  font-weight: bold;
}

.twobar .bar {
  position: absolute;
  width: 13px;
  height: 1.5px;
  background: #fff;
  top: 43%;
  right: 11px;
  transition: all 0.2s ease;
}

.twobar.arrow-down .bar {
  transform-origin: center;
  height: 2px;
}

.twobar.arrow-down .bar:nth-child(1) {
  transform: translateX(-20%) translateY(50%) scale(0.7, 0.7) rotate(55deg);
}

.twobar.arrow-down .bar:nth-child(2) {
  transform: translateX(20%) translateY(50%) scale(0.7, 0.7) rotate(-55deg);
}

.twobar.arrow-up .bar {
  background: #FFD801;
}

.twobar.arrow-up .bar:nth-child(1) {
  transform: translateX(-20%) translateY(50%) scale(0.7, 0.7) rotate(-55deg);
}

.twobar.arrow-up .bar:nth-child(2) {
  transform: translateX(20%) translateY(50%) scale(0.7, 0.7) rotate(55deg);
}

.job-new {
  background: #5FA6FC;
  color: white;
  font-size: 9px;
  padding: 5px;
  text-transform: uppercase;
}

.job-youtube {
  width: 450px;
  margin-left: 30px;
  margin-top: -25px;
  margin-bottom: 15px;
  float: right;
  display: inline-block;
  z-index: 10;
  position: relative;
}

@media (max-width: 992px) {
  .job-youtube {
    float: none;
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }
}

.job-youtube-wrapper {
  position: relative;
  padding-bottom: 56%;
  padding-top: 5px;
  height: 0;
}

.job-youtube-wrapper iframe {
  background: #000;
  border: 8px solid white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.job-pictures-small {
  margin: 20px 0;
}

.job-pictures-large {
  margin-top: 0;
  margin-bottom: 20px;
}

#jobs-result-container {
  display: flex;
  flex-wrap: wrap;
}

.job-container {
  display: flex;
  padding-bottom: 30px;
}

.job-container > a {
  width: 100%;
}

.job-container .job-inner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #f8f8f8;
  padding: 40px 40px 70px 40px;
}

.job-container .job-inner-container span {
  display: block;
  clear: both;
  font-size: 0.8rem;
  margin-bottom: 30px;
}

.job-container .job-inner-container .new-job {
  width: 100%;
  position: absolute;
  top: -12px;
  left: 0;
}

.job-container .job-inner-container .new-job div {
  padding: 4px 15px;
  font-size: 0.9rem;
  text-transform: none;
}

.job-container .job-inner-container .job-detail {
  display: flex;
  text-align: left;
  padding-left: 15%;
}

.job-container .job-inner-container .job-detail .job-detail-icon {
  float: left;
  width: 15%;
}

.job-container .job-inner-container .job-detail .job-detail-icon .material-icons {
  color: #5FA6FC;
  margin-right: 20px;
}

.job-container .job-inner-container .job-detail .job-detail-text {
  float: right;
  width: 85%;
}

.job-container .job-inner-container .job-detail .job-detail-text p {
  display: table-cell;
  vertical-align: middle;
}

.job-container .job-inner-container .job-title {
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 800;
  color: #111;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.job-container .job-inner-container .job-enterprise-type {
  margin-bottom: 5px;
}

.job-container .job-inner-container .job-city,
.job-container .job-inner-container .job-salary {
  margin-right: 5px;
}

.job-container .job-inner-container .job-city i,
.job-container .job-inner-container .job-salary i {
  margin: 0;
  width: auto;
  font-size: 19px;
}

.job-container .job-inner-container .job-salary {
  font-weight: normal;
}

.job-container .job-inner-container .job-city strong {
  font-weight: 600;
}

.job-container .job-inner-container .job-technos,
.job-container .job-inner-container .job-advantages,
.job-container .job-inner-container .job-created-at,
.job-container .job-inner-container .job-status {
  font-size: 12px;
}

.job-container .job-inner-container .job-type {
  font-size: 0.9rem;
  border-top: 1px solid lightgray;
  padding-top: 25px;
}

.job-container .job-inner-container .job-view-more {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 35px;
}

.job-container .job-inner-container .job-view-more p {
  width: 180px;
  color: #5FA6FC;
  border: 2px solid #5FA6FC;
  border-radius: 12px;
  padding: 12px 25px;
  font-size: 0.85rem;
  font-weight: 700;
  letter-spacing: 0.06rem;
  margin-left: auto;
  margin-right: auto;
}

.jobs-list-bottom {
  width: 100%;
  padding: 0 15px;
}

.jobs-list-bottom .jobs-total {
  float: left;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.jobs-list-bottom .jobs-pagination {
  float: right;
}

.jobs-noresult {
  text-align: center;
  padding: 40px 50px 60px 50px;
  width: 100%;
}

.jobs-noresult svg {
  width: 80px;
  height: auto;
  margin-bottom: 60px;
}

.jobs-noresult p {
  font-size: 1.2rem;
  font-weight: 700;
}

.jobs-table {
  border-top: 0;
}

.jobs-table thead tr {
  border-top: 0;
}

.jobs-table tr {
  cursor: pointer;
}

.jobs-table .job-title {
  font-weight: 800;
  color: #111;
}

.jobs-table .job-city,
.jobs-table .job-salary {
  margin-right: 5px;
}

.jobs-table .job-city i,
.jobs-table .job-salary i {
  margin: 0;
  width: auto;
  font-size: 19px;
}

.jobs-table .job-salary {
  font-weight: normal;
}

.jobs-table .job-city strong {
  font-weight: 600;
}

.jobs-table .job-technos,
.jobs-table .job-advantages,
.jobs-table .job-created-at,
.jobs-table .job-status {
  font-size: 12px;
}

.jobs-table .job-type {
  font-size: 11px;
}

@media (max-width: 768px) {
  .jobs-table .job-technos,
  .jobs-table .job-advantages,
  .jobs-table .job-created-at,
  .jobs-table .job-status {
    font-size: 11px;
  }
}

.job-meta-list {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.job-meta-list li {
  padding: 15px 0 15px 50px;
  position: relative;
  font-size: 13px;
}

.job-meta-list li:nth-child(odd) {
  background: rgba(246, 249, 252, 0.8);
}

.job-meta-list li:nth-child(even) {
  background: rgba(255, 255, 255, 0.8);
}

.job-meta-list i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  vertical-align: middle;
  color: #5FA6FC;
  font-size: 28px;
  float: left;
}

.job-meta-list .job-ref {
  font-weight: 600;
}

.job-meta-list .job-contact a {
  font-weight: 600;
}

.job-meta-list .job-contact p.job-contact-email {
  margin-top: 5px;
  font-size: 100%;
}

.job-meta-list .job-contact p.job-contact-email a {
  font-size: 100%;
  font-weight: 100;
}

.job-meta-list .job-ends-at i {
  color: #D81B60 !important;
}

@media (min-width: 768px) {
  .job-meta-list .job-contact a {
    word-break: keep-all;
  }
  .job-meta-list .job-ends-at strong {
    display: block;
  }
}

.xxl-button {
  width: 100%;
}

.xxl-button.button-fill-primary {
  font-size: 2rem;
}

.jobs-block h4 {
  font-weight: 700;
  text-align: left;
}

.jobs-block p a {
  color: #5FA6FC;
}

.jobs-block .job-meta-list i {
  font-size: 20px;
}

.jobs-block .job-meta-list li {
  font-size: 13px;
  padding-left: 40px;
}

.jobs-block .row p {
  margin-bottom: 10px;
  font-size: 13px;
}

.job-forums {
  margin-top: 20px;
}

.job-forums .form-actions {
  padding: 0;
}

.job-forums .form-actions span {
  display: inline-block;
  float: left;
  font-size: 13px;
  padding: 5px;
  color: #888;
}

@media (min-width: 768px) {
  .search-reset-link {
    width: 70%;
  }
  .job-highlights {
    width: 70%;
    display: inline-block;
  }
  .job-highlights .scrolling-list-container {
    position: absolute;
  }
  .job-highlights ul {
    text-align: left;
    margin-left: 22px;
  }
  .job-highlights ul li {
    margin-left: 10px;
  }
  .job-highlights ul label {
    font-size: 1rem;
  }
  .job-selector-lists {
    width: 70%;
    display: inline-block;
  }
  .job-selector-lists ul li label {
    font-size: 0.9rem;
  }
  .selector-container {
    margin-top: -9px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #professions-selector .twobar {
    margin-left: calc((70% - 193px) / 2 + 3px);
  }
  #types-selector .twobar {
    margin-left: calc((70% - 180px) / 2);
  }
  #work-modes-selector .twobar {
    margin-left: calc((70% - 174px) / 2 + 3px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .job-highlights, .job-selector-lists {
    top: -30px;
    position: relative;
  }
  .job-highlights ul {
    margin-left: 21px;
  }
  .job-selector-flex-container {
    display: flex;
    justify-content: space-between;
  }
  #professions-selector {
    flex: 1.02 1 0;
  }
  #professions-selector .selector-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
  }
  #professions-selector .twobar {
    margin-left: 0;
  }
  #types-selector {
    flex: 1.02 1 0;
    margin-top: 0;
  }
  #types-selector .selector-container {
    display: flex;
    justify-content: flex-end;
  }
  #types-selector .twobar {
    margin-left: 0;
  }
  #work-modes-selector {
    flex: 1 1 0;
    margin-top: 0;
  }
  #work-modes-selector .selector-container {
    display: flex;
    justify-content: flex-end;
  }
  #work-modes-selector .twobar {
    margin-left: 0;
  }
  .selector-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 1200px) {
  .job-highlights, .job-selector-lists {
    padding: 0 5%;
    top: -25px;
    position: relative;
  }
  .job-highlights ul {
    margin-left: 29px;
  }
  .job-selector-flex-container {
    display: flex;
    justify-content: space-between;
  }
  #professions-selector {
    flex: 1.02 1 0;
  }
  #professions-selector .selector-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 33px;
  }
  #professions-selector .twobar {
    margin-left: 0;
  }
  #types-selector {
    flex: 1.02 1 0;
    margin-top: 0;
  }
  #types-selector .selector-container {
    display: flex;
    justify-content: flex-end;
  }
  #types-selector .twobar {
    margin-left: 0;
  }
  #work-modes-selector {
    flex: 1 1 0;
    margin-top: 0;
  }
  #work-modes-selector .selector-container {
    display: flex;
    justify-content: flex-end;
  }
  #work-modes-selector .twobar {
    margin-left: 0;
  }
}

.pagination {
  margin: 0em;
  vertical-align: middle;
  font-weight: normal;
  line-height: 1 !important;
}

.pagination .item {
  border: 1px solid #5FA6FC;
  padding: 8px;
  text-align: center;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 1px;
}

.pagination .item a {
  display: block;
}

.pagination .disabled {
  opacity: .5;
}

.pagination .active {
  background: #5FA6FC;
  color: white !important;
  border-color: #5FA6FC;
}

.message {
  border: 2px solid #666;
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 35px;
  border-radius: 5px;
  font-weight: 400;
}

.message i {
  color: #666;
  font-size: 30px;
  margin-bottom: 10px;
}

.message-info {
  background: white;
  border-color: #5FA6FC;
  color: #5FA6FC !important;
}

.message-info i {
  color: #5FA6FC;
}

.message-success {
  background: #fdfef9;
  border-color: #C0CA33;
  color: black;
}

.message-success i {
  color: #C0CA33;
}

.message-error {
  background: #fffbfc;
  border-color: #D81B60;
  color: #D81B60;
}

.message-error i {
  color: #D81B60;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 1000;
  transition: all .25s linear;
  opacity: .5;
}

.modal-shown .modal-overlay {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  background: white;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  transition: all .25s;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 1010;
  perspective: 1000;
  backface-visibility: hidden;
}

.modal-shown .modal {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.modal iframe {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overflow-scrolling: touch !important;
}

.modal-body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto !important;
}

.modal-close {
  cursor: pointer;
  color: #5FA6FC;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-close i {
  font-size: 34px !important;
}

.addthis_inline_share_toolbox {
  height: 50px;
  display: block;
}

@media (max-width: 768px) {
  .at-resp-share-element {
    text-align: center;
  }
}

.login {
  background: white;
  position: relative;
  z-index: 1000 !important;
  margin: -90px auto -120px;
  max-width: 450px;
  padding: 30px;
  border-radius: 5px;
}

.login .form-actions {
  text-align: center;
  padding: 5px 0;
}

.login .form-row {
  padding: 10px 0;
}

.login .button {
  margin: 1px;
}

.login .button-small {
  margin: 8px 0;
  width: 80%;
}

.login .form-checkboxes {
  margin-top: -5px;
  text-align: center;
}

.login .form-errors {
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.login .form-hint {
  display: block;
  text-align: left;
}

.login-close {
  position: absolute;
  top: 20px;
  right: 25px;
}

.login-close i {
  font-size: 30px;
}

.login-forget {
  display: block;
  font-size: 10px;
  text-align: center;
  padding: 10px 0 15px;
}

.login-logo {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 80px;
  margin-top: -50px;
}

.login-or {
  margin-top: 10px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}

.login-or span {
  font-size: 10px;
  padding: 5px 10px;
  display: inline-block;
  background: white;
  z-index: 2;
  position: relative;
  color: #000;
}

.login-or:after {
  content: '';
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  position: absolute;
  border-bottom: 1px dotted #000;
  z-index: 1;
}

.dashboard-block {
  margin-bottom: 30px;
}

.dashboard-block .table {
  line-height: 1.5;
}

.dashboard-block h3 {
  font-size: 13px;
  margin: 3px 0;
}

.dashboard-block h3 i {
  margin-right: 5px;
  background: #FFD801;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  max-width: 28px;
}

.dashboard-block .icon-before {
  color: black;
  font-size: 15px;
}

.dashboard-delete-account {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 20px !important;
  display: block;
}

.dashboard-delete-account button {
  display: block;
  font-size: 11px;
  text-align: left;
  color: #5FA6FC;
  cursor: pointer;
}

.dashboard-noresult {
  font-size: 12px;
  line-height: 2;
  padding: 30px;
  border-radius: 2px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 14px;
  text-transform: uppercase;
}

.resume-block form {
  max-width: 100%;
}

.resumes {
  text-align: left;
  padding-top: 8px;
}

.resumes-application {
  text-align: center;
  border-top: 0;
  margin-bottom: 20px;
}

.resume {
  border: 1px solid #ddd;
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  text-align: center;
  line-height: 110px;
  position: relative;
  transition: .5s box-shadow;
}

.resume i {
  font-size: 48px;
}

.resume.selected {
  box-shadow: 0 10px 20px rgba(95, 166, 252, 0.19), 0 6px 6px rgba(95, 166, 252, 0.23), 0 0px 10px rgba(95, 166, 252, 0.23);
  border: 1px solid #5FA6FC !important;
}

.resume-upload {
  position: relative;
  cursor: pointer;
  line-height: 115px;
}

.resume-upload i {
  color: #ccc;
  cursor: pointer;
}

.resume-upload input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}

.resume-file {
  display: block;
  color: #5FA6FC;
}

.resume-view,
.resume-delete {
  position: absolute;
  top: 8px;
  right: 5px;
  display: block;
  line-height: 1;
}

.resume-view i,
.resume-delete i {
  color: #999;
  font-size: 20px;
}

.resume-view {
  left: 5px;
  right: auto;
}

.resume-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 600;
  background: lightyellow;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.resume-loading {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 38px auto;
  width: 40px;
  height: 40px;
  background-color: #FFD801;
  animation: resume-loading-anim .75s infinite ease-in-out;
}

.resume-processing i {
  visibility: hidden;
}

.resume-processing .resume-loading {
  visibility: visible;
}

@keyframes resume-loading-anim {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.blog-snippet {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog-post h1,
.blog-snippet h1 {
  font-size: 38px;
}

.blog-post h1 a,
.blog-snippet h1 a {
  color: inherit;
}

.blog-post {
  margin-top: -15px;
}

.blog-post h1 {
  font-size: 42px;
}

.blog-meta i {
  color: #5FA6FC;
  margin-right: 5px;
  margin-left: 10px;
  font-size: 20px;
}

.blog-meta i:first-child {
  margin-left: 0;
}

.blog-meta a {
  color: inherit;
}

.blog-link-more {
  margin-top: 20px;
  display: block;
}

.meta-list {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.meta-list li {
  padding: 15px 0 15px 50px;
  position: relative;
  font-size: 13px;
}

.meta-list li:nth-child(odd) {
  background: rgba(246, 249, 252, 0.8);
}

.meta-list li:nth-child(even) {
  background: rgba(255, 255, 255, 0.8);
}

.meta-list i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  vertical-align: middle;
  color: #5FA6FC;
  font-size: 28px;
  float: left;
}

.references-block .item {
  position: relative;
}

.references-block .icon-quotes {
  position: absolute;
  right: 10px;
  top: -5px;
  height: 60px;
}

.references-block .portrait {
  width: 150px;
  height: 150px;
  margin: 15px auto;
  display: block;
}

.success-stories-intro {
  text-align: center;
  color: #5FA6FC;
  font-size: 1.5rem;
  margin-bottom: 30px;
  line-height: 2rem;
}

#references-carousel {
  margin-top: 20px;
}

#references-carousel .reference-wrapper {
  background: #5FA6FC;
  text-align: center;
  padding: 0 50px;
}

#references-carousel .reference-meta.stickers {
  text-align: center;
  margin-bottom: 15px;
}

#references-carousel .reference-meta.stickers .sticker-primary, #references-carousel .reference-meta.stickers .sticker-secondary {
  width: fit-content;
  float: none;
  margin: 0 auto;
  padding: 8px 12px;
}

#references-carousel .reference-text {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
}

#references-carousel .reference-text p {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-style: italic;
  line-height: 1.8;
  margin-top: 5px;
  margin-bottom: 10px;
  word-break: break-word;
}

#references-carousel .speech-bubble {
  position: relative;
  background: white;
  border-radius: 1em;
  padding: 15px;
}

#references-carousel .flickity-viewport {
  padding-top: 5px;
  background: #5FA6FC;
}

#references-carousel .flickity-viewport .small-6 {
  margin-bottom: 50px;
}

#references-carousel .carousel-cell {
  width: 100% !important;
}

#references-carousel .flickity-prev-next-button.previous {
  left: 5px !important;
}

#references-carousel .flickity-prev-next-button.next {
  right: 5px !important;
}

@media (min-width: 992px) {
  .reference-wrapper .portrait-and-details {
    float: right;
    width: 25%;
  }
  .reference-wrapper .reference-text {
    float: left;
    height: 295px;
    padding-bottom: 0;
    line-height: 295px;
    width: 70%;
  }
  .reference-wrapper .reference-text p {
    text-align: left;
    margin-top: 0;
  }
  .reference-wrapper .speech-bubble:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-left-color: white;
    border-right: 0;
    border-top: 0;
    border-left: 60px solid white;
    margin-top: -15px;
    margin-right: -59px;
  }
  .reference-wrapper.reference-odd .portrait-and-details {
    float: left;
  }
  .reference-wrapper.reference-odd .reference-text {
    float: right;
  }
  .reference-wrapper.reference-odd .speech-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-right-color: white;
    border-left: 0;
    border-top: 0;
    border-right: 60px solid white;
    margin-top: -15px;
    margin-left: -60px;
  }
}

@media (min-width: 1200px) {
  .reference-wrapper .reference-text {
    width: 73%;
  }
}

.team-block .teammates-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.team-block .teammates-row {
  display: inline-block;
  width: 100%;
}

.team-block .teammate-div {
  padding: 10px;
  width: 100%;
}

.team-block .item {
  margin-bottom: 0;
}

.team-block .teammate-inner-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #CFE6FF;
  padding: 15px;
  text-align: center;
}

.team-block .portrait {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}

.team-block .item {
  padding-top: 20px;
}

.team-block .item:after {
  content: "";
  display: table;
  clear: both;
}

.team-meta .teammate-name.stabilo {
  text-transform: none;
}

.team-meta p {
  margin-bottom: 15px;
}

.team-location, .team-experience {
  font-size: 14px;
  color: #111;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: -5px;
}

.team-location i, .team-experience i {
  vertical-align: middle;
  color: #5FA6FC;
  margin-top: -2px;
}

.team-list {
  margin-left: -10px;
  margin-bottom: 20px;
  text-align: left;
}

.team-list li {
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.team-list li:after {
  content: "";
  display: table;
  clear: both;
}

.team-contact {
  margin-bottom: 20px;
}

.team-contact strong {
  font-size: 1.2em;
  font-weight: 700;
  display: block;
  padding: 2px;
}

.team-contact a {
  color: #5FA6FC;
  font-size: 13px;
  font-weight: 700;
}

.group-picture-container {
  padding-left: 10px;
  padding-right: 10px;
}

.group-picture {
  text-align: center;
  margin-bottom: 20px;
}

.group-picture img {
  width: 100%;
}

.team-adjustment-div {
  display: none;
}

.contact-team-block .small-6 {
  float: none;
  width: 100%;
}

.contact-team-block .small-3 {
  display: none;
}

.contact-teammate-container {
  text-align: center;
}

.contact-teammate-inner-container {
  background-color: #5FA6FC;
  display: inline-block;
}

.contact-teammate-inner-container .portrait {
  margin: 10px;
}

.contact-teammate-inner-container .team-meta {
  background: white;
  text-align: center;
}

.contact-teammate-inner-container .team-meta .stickers {
  margin-bottom: 10px;
}

.contact-teammate-inner-container .team-meta .stickers .sticker-primary, .contact-teammate-inner-container .team-meta .stickers .sticker-secondary {
  display: inline-block;
  float: none;
  font-size: 0.9rem;
  padding: 4px 6px;
  clear: none;
}

.contact-teammate-inner-container .team-meta .stickers .sticker-primary {
  text-transform: uppercase;
}

.contact-teammate-inner-container .team-meta .stickers .sticker-secondary {
  text-transform: none;
}

.contact-teammate-inner-container .team-meta .contact-teammate-function {
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 700;
}

.contact-teammate-inner-container .team-meta .contact-teammate-function-3-lines {
  margin-bottom: 2px;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 700;
}

.contact-teammate-inner-container .team-meta .contact-teammate-function-span {
  display: inline-block;
  margin-bottom: 6px;
}

.contact-teammate-inner-container .team-contact {
  margin-bottom: 0;
}

.contact-teammate-inner-container .team-contact strong {
  font-size: 1rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-notice {
  float: left;
  font-size: 0.6rem;
  text-align: left;
}

.visible-tiny .contact-teammate-inner-container {
  border-radius: 85px;
  width: 170px;
}

.visible-tiny .contact-teammate-inner-container .team-meta {
  min-width: 150px;
  height: 225px;
  margin: -3px 10px 10px 10px;
  padding-top: 13px;
  border-radius: 0 0 75px 75px;
}

.hidden-tiny .contact-teammate-inner-container {
  border-radius: 85px 85px 85px 0;
  width: 420px;
  height: 170px;
}

.hidden-tiny .contact-teammate-inner-container .portrait {
  float: left;
}

.hidden-tiny .contact-teammate-inner-container .team-meta {
  float: right;
  min-width: 240px;
  height: 150px;
  margin: 10px 10px 10px 0;
  padding-top: 13px;
  padding-right: 30px;
  border-radius: 0 75px 75px 0;
}

@media (min-width: 768px) and (max-width: 992px) {
  .group-picture img {
    width: 80%;
  }
  .team-block .teammate-div {
    padding: 10px 10px;
  }
  .team-block .teammate-inner-container {
    text-align: center;
  }
  .team-block .teammate-inner-container .portrait {
    float: none;
  }
  .team-block .teammate-inner-container .stickers {
    text-align: center;
    white-space: nowrap;
  }
  .team-block .teammate-inner-container .stickers .sticker-primary,
  .team-block .teammate-inner-container .stickers .sticker-secondary {
    margin: 0;
    display: inline-block;
    float: none;
    clear: both;
    padding: 8px 15px;
    font-size: 12px;
    margin: 0;
  }
  .team-block .team-list {
    margin: 0 10%;
  }
}

@media (min-width: 992px) {
  .group-picture img {
    width: 800px;
  }
  .team-block .teammate-div {
    padding: 10px 45px;
  }
  .team-block .teammate-inner-container .portrait {
    float: left;
  }
  .team-block .teammate-inner-container .team-meta {
    float: right;
    padding-top: 10px;
    min-width: calc((1000px / 2) - (2 * (60px + 15px) + 120px));
    max-width: calc((1000px / 2) - (2 * (60px + 15px) + 120px));
  }
  .contact-team-block .row2 {
    margin-top: 30px;
  }
  .contact-team-block .small-6 {
    float: left;
    width: 50%;
  }
  .contact-team-block .small-3 {
    display: block;
  }
}

@media (min-width: 1200px) {
  .team-block .teammate-div {
    padding: 10px 50px;
  }
  .team-block .teammate-inner-container .team-meta {
    min-width: calc((1000px / 2) - (2 * (55px + 15px) + 120px));
    max-width: calc((1000px / 2) - (2 * (55px + 15px) + 120px));
  }
}

@media (min-width: 768px) {
  .team-block .teammates-row {
    display: flex;
  }
  .team-block .teammate-div {
    flex: 1;
  }
  .team-adjustment-div {
    display: block;
  }
}

.testimonies-block .item {
  position: relative;
}

.testimonies-block .icon-quotes {
  position: absolute;
  right: 10px;
  top: -5px;
  height: 60px;
}

.testimonies-block .portrait {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  display: block;
}

.testimonies-meta p {
  font-style: italic;
  line-height: 1.8;
  margin-top: 5px;
  margin-bottom: 15px;
}

.testimonies-subtitle div {
  margin-bottom: 35px;
}

.testimonies-subtitle div:first-of-type {
  padding-left: 10%;
}

.testimonies-subtitle div p {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
}

.flickity-viewport {
  padding-top: 5px;
}

.flickity-viewport .small-6 {
  margin-bottom: 50px;
}

.carousel-cell {
  width: 100% !important;
}

#testimonies-carousel .flickity-prev-next-button {
  top: 45% !important;
}

#testimonies-carousel .flickity-prev-next-button.previous {
  left: -25px !important;
}

#testimonies-carousel .flickity-prev-next-button.next {
  right: -25px !important;
}

@media (min-width: 992px) {
  .testimonies-block .portrait {
    float: left;
  }
  .testimonies-meta {
    float: left;
    margin-left: 25px;
    width: calc(100% - 200px);
  }
  .testimonies-meta p {
    text-align: left;
  }
}

.sticker-primary,
.sticker-secondary {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 8px 12px;
  margin: 10px 0;
}

.sticker-primary {
  background: #FFD801;
  color: #111;
}

.sticker-secondary {
  background: #111;
  color: white;
}

.stickers:after {
  content: "";
  display: table;
  clear: both;
}

.stickers .sticker-primary,
.stickers .sticker-secondary {
  float: left;
  clear: left;
  margin: 0;
}

.stickers .sticker-primary {
  font-weight: 600;
  padding: 5px 12px;
  text-transform: none;
  font-size: 14px;
}

.stickers .sticker-secondary {
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 50px 8px 12px;
  font-size: 15px;
}

.portrait {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#homepage-index .timeline {
  padding-bottom: 100px;
  margin-bottom: -70px;
  margin-top: -30px;
}

@media (min-width: 768px) {
  #homepage-index .timeline {
    margin-top: -35px;
  }
}

#homepage-index .timeline-past {
  display: block;
}

#homepage-index .container {
  position: relative;
}

.homepage-stats .item:nth-child(1) .circle-strong {
  padding-top: 5px;
  padding-right: 6px;
}

.homepage-stats .item:nth-child(2) .circle-strong {
  padding-top: 8px;
  padding-right: 4px;
}

.homepage-stats .item:nth-child(3) .circle-strong {
  padding-top: 5px;
  padding-right: 2px;
}

#faq-index .intro .button {
  margin-top: 2em;
}

#faq-index p {
  margin-bottom: 10px;
}

#faq-index .faqs {
  margin-top: 50px;
}

#faq-index .text h4 {
  font-weight: 700;
}

#faq-index .text .faq-answer {
  margin-bottom: 25px;
  padding-left: 25px;
  border-left: 8px solid #5FA6FC;
  margin-left: 2px;
}

#about-index .block-splash {
  background-image: url(/img/splashes/address.jpg);
  background-position: center bottom;
}

#about-index .intro .button {
  margin: 25px 0 10px;
}

#about-index .logo {
  width: 180px;
  margin: -5px auto 10px;
  display: block;
}

@media (min-width: 768px) {
  .about-identity p {
    margin-top: 10px;
  }
}

.about-identity p strong {
  color: #056ff0;
  font-weight: 900;
}

.about-identity p em {
  font-weight: 500;
  color: black;
}

.about-identity-footer p {
  text-align: center;
  margin-top: 40px;
  font-size: 32px;
  font-weight: 500;
  color: #2d8afb;
}

@media (max-width: 768px) {
  .about-identity-footer p {
    font-size: 24px;
  }
  .about-identity-footer p br {
    display: none;
  }
}

.about-identity-bullets {
  padding: 30px 0;
}

.about-identity-bullets h2 {
  margin-bottom: 30px;
}

.about-identity-bullets i {
  font-size: 48px;
  color: #4698fc;
  margin-bottom: 10px;
}

.about-identity-bullets p {
  font-weight: 600;
}

.about-identity-bullets .row {
  margin-bottom: 20px;
}

#events-index .block-splash {
  background-image: url(/img/splashes/events.jpg);
  background-position: center center;
}

.companies-enums .item p {
  padding-left: 30px;
}

.companies-stats .item:nth-child(1) .circle-strong {
  padding-top: 3px;
  padding-right: 6px;
}

.companies-stats .item:nth-child(2) .circle-strong {
  padding-top: 4px;
  padding-right: 6px;
}

.companies-stats .item:nth-child(3) .circle-strong {
  padding-top: 0;
  padding-right: 4px;
}

.companies-stats .item:nth-child(4) .circle-strong {
  padding-top: 8px;
  padding-right: 4px;
}

.companies-stats .item:nth-child(5) .circle-strong {
  padding-top: 3px;
  padding-right: 1px;
}

.companies-stats .item:nth-child(6) .circle-strong {
  padding-top: 2px;
  padding-right: 3px;
}

.companies-stats .centered {
  margin-top: 40px;
}

@media (max-width: 992px) {
  .companies-stats .item {
    margin-bottom: 0;
  }
}

.companies-following .circle-letter {
  margin-bottom: -45px;
  margin-left: -10px;
  position: relative;
  z-index: 100;
  border: 2px solid #5FA6FC;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  color: #5FA6FC;
}

.companies-following .sticker-primary,
.companies-following .sticker-secondary {
  padding-left: 40px;
  margin-bottom: 15px;
}

.companies-following .dots {
  margin: 10px 0 30px;
  position: relative;
  opacity: 0;
  transition: all 1s ease-in-out;
}

.companies-following .dots.appeared {
  opacity: 1;
}

.companies-following .dots:before {
  display: block;
  content: '';
  width: 100%;
  border: 2px solid #d8e4ef;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.companies-following .dot:before {
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  background: #cadae9;
  border-radius: 50%;
  margin: 0 auto;
}

.companies-following .item {
  margin-bottom: 20px;
  transition: all 1s ease-in-out;
  opacity: 0;
  transform: translateY(100px);
}

.companies-following .item.appeared {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .companies-following .item:nth-child(1) {
    transition-delay: 150ms;
  }
  .companies-following .item:nth-child(2) {
    transition-delay: 300ms;
  }
  .companies-following .item:nth-child(3) {
    transition-delay: 450ms;
  }
  .companies-following .item:nth-child(4) {
    transition-delay: 600ms;
  }
  .companies-following .item:nth-child(5) {
    transition-delay: 750ms;
  }
  .companies-following .item:nth-child(6) {
    transition-delay: 900ms;
  }
  .companies-following .item:nth-child(7) {
    transition-delay: 1050ms;
  }
  .companies-following .item:nth-child(8) {
    transition-delay: 1200ms;
  }
}

.companies-identity {
  border-top: 1px solid #CFE6FF !important;
}

@media (min-width: 768px) {
  .companies-identity p {
    margin-top: 10px;
  }
}

.companies-identity p strong {
  color: #0458be;
  font-weight: 900;
}

.companies-identity p em {
  font-weight: 500;
  color: black;
}

.companies-identity-footer p {
  text-align: center;
  margin-top: 40px;
  font-size: 32px;
  font-weight: 500;
  color: #2d8afb;
}

@media (max-width: 768px) {
  .companies-identity-footer p {
    font-size: 24px;
  }
  .companies-identity-footer p br {
    display: none;
  }
}

.what-can-we-do-block {
  margin-top: 40px;
}

.companies-identity-list {
  margin-top: 40px;
}

.companies-identity-list h3 {
  color: #0458be;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px dotted gray;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.companies-identity-list ul {
  margin-left: 20px;
}

.companies-identity-list li {
  list-style: square;
  line-height: 1.5;
}

#private-dashboard .buttons-round {
  margin-top: -90px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

#jobs-index .icon-favorite {
  font-size: 16px;
}

#jobs-index .form-actions {
  margin-top: -40px;
  position: relative;
  z-index: 1;
}

#jobs-show header .block-overlay {
  background: rgba(0, 0, 0, 0.7);
}

#jobs-index .buttons-round,
#jobs-show .buttons-round {
  margin-top: -90px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

#jobs-show .buttons-round {
  margin-bottom: 50px;
}
