
.circle {
    text-align: center;
    border: 2px solid $color-black;
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
    flex: 0 0 auto;
    background: white;

    div {
        margin-top: -5px; }

    small {
        display: block;
        font-size: 10px;
        font-weight: 600;
        color: #555; } }

.circle-letter {
    text-align: center;
    border: 2px solid $color-black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white; }

.circle-strong {
    letter-spacing: -5px;
    display: block;
    margin: 0 auto 15px;
    text-align: center;
    background: $color-blue;
    color: white;
    font-weight: 800;
    font-size: 54px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-transform: uppercase; }

