
$tms-container-width: 1000px;
$portait-w-h: 120px;

.team-block {
    .teammates-container {
        max-width: $tms-container-width;
        margin-left: auto;
        margin-right: auto; }

    .teammates-row {
        display: inline-block;
        width: 100%; }

    .teammate-div {
        padding: 10px;
        width: 100%; }

    .item {
        margin-bottom: 0; }

    .teammate-inner-container {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: $color-lightblue;
        padding: 15px;
        text-align: center; }
    .portrait {
        width: $portait-w-h;
        height: $portait-w-h;
        margin-bottom: 15px; }

    .item {
        @include clearfix();
        padding-top: 20px; } }

.team-meta {
    .teammate-name.stabilo {
        text-transform: none; }
    p {
        margin-bottom: 15px; } }

.team-location, .team-experience {
    font-size: 14px;
    color: $color-black;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: -5px;

    i {
        vertical-align: middle;
        color: $color-blue;
        margin-top: -2px; } }


.team-list {
    margin-left: -10px;
    margin-bottom: 20px;
    text-align: left;

    li {
        @include clearfix;
        font-size: 13px;
        display: flex;
        align-items: center;
        margin-bottom: 15px; } }

.team-contact {
    margin-bottom: 20px;

    strong {
        font-size: 1.2em;
        font-weight: 700;
        display: block;
        padding: 2px; }

    a {
        color: $color-blue;
        font-size: 13px;
        font-weight: 700; } }
.group-picture-container {
    padding-left: 10px;
    padding-right: 10px; }

.group-picture {
    text-align: center;
    margin-bottom: 20px;
    img {
        width: 100%; } }

.team-adjustment-div {
    display: none; }

.contact-team-block {
    .small-6 {
        float: none;
        width: 100%; }
    .small-3 {
        display: none; } }

.contact-teammate-container {
    text-align: center; }

.contact-teammate-inner-container {
    background-color: $color-blue;
    display: inline-block;
    .portrait {
        margin: 10px; }
    .team-meta {
        background: white;
        text-align: center;
        .stickers {
            margin-bottom: 10px; }
        .stickers .sticker-primary, .stickers .sticker-secondary {
            display: inline-block;
            float: none;
            font-size: 0.9rem;
            padding: 4px 6px;
            clear: none; }
        .stickers .sticker-primary {
            text-transform: uppercase; }
        .stickers .sticker-secondary {
            text-transform: none; }
        .contact-teammate-function {
            margin-bottom: 10px;
            font-size: 0.8rem;
            font-weight: 700; }
        .contact-teammate-function-3-lines {
            margin-bottom: 2px;
            font-size: 0.8rem;
            line-height: 0.9rem;
            font-weight: 700; }
        .contact-teammate-function-span {
            display: inline-block;
            margin-bottom: 6px; } }

    .team-contact {
        margin-bottom: 0;
        strong {
            font-size: 1rem; } } }

.grecaptcha-badge {
    visibility: hidden; }

.recaptcha-notice {
    float: left;
    font-size: 0.6rem;
    text-align: left; }

.visible-tiny .contact-teammate-inner-container {
    border-radius: 85px;
    width: 170px;
    .team-meta {
        min-width: 150px;
        height: 225px;
        margin: -3px 10px 10px 10px;
        padding-top: 13px;
        border-radius: 0 0 75px 75px; } }

.hidden-tiny .contact-teammate-inner-container {
    border-radius: 85px 85px 85px 0;
    width: 420px;
    height: 170px;
    .portrait {
        float: left; }
    .team-meta {
        float: right;
        min-width: 240px;
        height: 150px;
        margin: 10px 10px 10px 0;
        padding-top: 13px;
        padding-right: 30px;
        border-radius: 0 75px 75px 0; } }

@media (min-width: $screen-small) and (max-width: $screen-medium) {
    .group-picture img {
        width: 80%; }

    .team-block {
        .teammate-div {
            padding: 10px 10px; }

        .teammate-inner-container {
            text-align: center;

            .portrait {
                float: none; }

            .stickers {
                text-align: center;
                white-space: nowrap;

                .sticker-primary,
                .sticker-secondary {
                    margin: 0;
                    display: inline-block;
                    float: none;
                    clear: both;
                    padding: 8px 15px;
                    font-size: 12px;
                    margin: 0; } } }

        .team-list {
            margin: 0 10%; } } }

@media (min-width: $screen-medium) {
    .group-picture img {
        width: 800px; }

    .team-block {
        .teammate-div {
            padding: 10px 45px; }

        .teammate-inner-container {
            .portrait {
                float: left; }
            .team-meta {
                float: right;
                padding-top: 10px;
                min-width: calc((#{$tms-container-width} / 2) - (2 * (60px + 15px) + #{$portait-w-h}));
                max-width: calc((#{$tms-container-width} / 2) - (2 * (60px + 15px) + #{$portait-w-h})); } } }

    .contact-team-block {
        .row2 {
            margin-top: 30px; }
        .small-6 {
            float: left;
            width: 50%; }
        .small-3 {
            display: block; } } }

@media (min-width: $screen-large) {
    .team-block {
        .teammate-div {
            padding: 10px 50px; }

        .teammate-inner-container {
            .team-meta {
                min-width: calc((#{$tms-container-width} / 2) - (2 * (55px + 15px) + #{$portait-w-h}));
                max-width: calc((#{$tms-container-width} / 2) - (2 * (55px + 15px) + #{$portait-w-h})); } } } }

@media (min-width: $screen-small) {
    .team-block {
        .teammates-row {
            display: flex; }

        .teammate-div {
            flex: 1; } }

    .team-adjustment-div {
        display: block; } }
