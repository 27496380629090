.testimonies-block {
    .item {
        position: relative; }

    .icon-quotes {
        position: absolute;
        right: 10px;
        top: -5px;
        height: 60px; }

    .portrait {
        width: 130px;
        height: 130px;
        margin: 0 auto;
        display: block; } }

.testimonies-meta {
    p {
        font-style: italic;
        line-height: 1.8;
        margin-top: 5px;
        margin-bottom: 15px; } }

.testimonies-subtitle {
    div {
        margin-bottom: 35px;
        &:first-of-type {
            padding-left: 10%; }
        p {
            text-align: center;
            font-size: 1.7rem;
            font-weight: bold; } } }

.flickity-viewport {
    padding-top: 5px;
    .small-6 {
        margin-bottom: 50px; } }

.carousel-cell {
    width: 100%!important; }

#testimonies-carousel .flickity-prev-next-button {
    top: 45%!important;
    &.previous {
        left: -25px!important; }
    &.next {
        right: -25px!important; } }

// tablet

@media (min-width: $screen-medium) {
    .testimonies-block {
        .portrait {
             float: left; } }

    .testimonies-meta {
        float: left;
        margin-left: 25px;
        width: calc(100% - 200px);

        p {
            text-align: left; } } }
