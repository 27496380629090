// ::-webkit-scrollbar
//     -webkit-appearance: none
//     width: 7px

// ::-webkit-scrollbar-thumb
//     border-radius: 4px
//     background-color: rgba(0,0,0,.5)
//     -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    z-index: 1000;
    transition: all .25s linear;
    opacity: .5;

    .modal-shown & {
        opacity: 1; } }

.modal {
    position: fixed;
    // top: 15px
    // left: 2%
    // transform: scale(.9)
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.9);
    opacity: 0;
    background: white;
    width: 95%;
    max-width: 800px;
    max-height: 90vh;
    transition: all .25s;
    overflow: hidden;
    // overflow-y: auto !important
    // overflow-scrolling: touch !important
    border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    z-index: 1010;
    perspective: 1000;
    backface-visibility: hidden;

    @media (min-width: $screen-small) {
        // top: 100px
        // left: 50%
        // margin-left: -400px
        // top: 50%
        // left: 50%
 }        // transform: translate(-50%, -50%) scale(.9)

    .modal-shown & {
        opacity:  1;
        transform: translate(-50%, -50%) scale(1); }

    iframe {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        overflow-scrolling: touch !important; } }

.modal-body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: auto !important; }

.modal-close {
    cursor: pointer;
    color: $color-blue;
    position: absolute;
    top: 10px;
    right: 10px;

    i {
        font-size: 34px !important; } }
