$menu-breakpoint:   768px;
$menu-height:       65px;
$menu-width:        250px;
$menu-margin:        15px;
$menu-transition:    .20s;

// html
//     min-height: 100%
//     // overflow: hidden

@media (max-width: 960px) {
    .topbar-minor {
        display: none; } }

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $menu-height;
    margin-bottom: -100px;
    z-index: 1000;
    text-transform: uppercase;
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    color: white;
    text-rendering: geometricPrecision;
    padding-right: 15px;
    backface-visibility: hidden;

    .not-touch-device & {
        transition: all $menu-transition; } }

.topbar-container {
    height: $menu-height;
    display: block;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    position: relative; }

.topbar-logo {
    padding: 12px $menu-margin;
    display: block;
    overflow: hidden;

    svg {
        display: inline-block;
        width: 185px;
        height: 40px;
        transition: all $menu-transition;
        transform-origin: left center;

        @media (min-width: $screen-medium) {
            transform: scale(1.25); }

        #job, #opportun, #it {
            fill: white;
            transition: all $menu-transition; } } }

.topbar-primary {
    background: rgba(black, .6);
    border-bottom: 1px solid rgba(white, .3);
    color: white;
    opacity: 1;

    svg {
        transform: scale(1);

        #opportun {
            fill: white;
            opacity: .8; } } }

.topbar-secondary {
    background: linear-gradient(darken(white, 3), rgba(white, .95));
    border-bottom: 1px solid lightgray;
    color: black;

    svg {
        transform: scale(1);

        #job, #it {
            fill: $color-blue; }
        #opportun {
            fill: black; } }

    .navigation-button {
        border-color: $color-blue !important;
        color: $color-blue;
        cursor: pointer;
        border: 4px solid #000; } }

.site-menu-opened .navigation {
    color: black; }

/* MENU ON SMALL SCREEN */

/* Configuration Parameters */
$lineWidth: 3px;
$lineLength: 25px;
$baseMargin: 8px;

// To use mixins - @include (set value here);
// i.e. - @include color(red);
@mixin color($color) {
    color: $color; }

@mixin rotate($rotateDegrees) {
    -webkit-transform: $rotateDegrees;
    -moz-transform: $rotateDegrees;
    -ms-transform: $rotateDegrees;
    -o-transform: $rotateDegrees;
    transform: $rotateDegrees; }

@mixin animation-duration($animationDuration) {
    -webkit-transition-duration: $animationDuration;
    -moz-transition-duration: $animationDuration;
    -ms-transition-duration: $animationDuration;
    -o-transition-duration: $animationDuration;
    transition-duration: $animationDuration; }

@mixin animation-delay($animationDelay) {
    -webkit-transition-delay: $animationDelay;
    -moz-transition-delay: $animationDelay;
    -ms-transition-delay: $animationDelay;
    -o-transition-delay: $animationDelay;
    transition-delay: $animationDelay; }

.nav {
    max-height: 0;
    overflow: hidden;
    //margin-top: 43px
    margin-left: -40px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0; }
    li.active > label, li.active > label > a, li.active > a {
        background: #5FA6FC !important; }
    input[type=checkbox] {
        display: none; } }

.nav__list {
    background: #fff; }

#hamburger-checkbox {
    display: none; }

.hamburger-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    cursor: pointer;
    height: $baseMargin*2 + $lineWidth;
    width: $lineLength;
    span {
        display: block;
        top: $baseMargin;
        width: $lineLength;
        height: $lineWidth;
        background-color: #fff;
        position: relative;
        @include animation-duration(.2s);
        @include animation-delay(0s);

        &:after, &:before {
            display: block;
            content: '';
            position: absolute;
            width: $lineLength;
            height: $lineWidth;
            background-color: #fff;
            @include animation-duration(.2s);
            @include animation-delay(.0s); }

        &:before {
            margin-top: -$baseMargin; }

        &:after {
            margin-top: $baseMargin; } } }

#hamburger-checkbox:checked ~ .nav {
    width: 250px;
    //max-height: 99999px
    max-height: calc(100vh - 67px);
    overflow: scroll;
    -webkit-transition: max-height 0.5s;
 }    //animation: fade-opacity-in .8s none;

#hamburger-checkbox:checked ~ .hamburger-icon {
    span {
        background-color: rgba(0,0,0,0);
        @include animation-duration(.2s);

        &:before, &:after {
            margin-top: 0px;
            @include animation-duration(.2s); }

        &:before {
            @include rotate(rotate(45deg)); }

        &:after {
            @include rotate(rotate(-45deg)); } } }

.nav .label-link {
    padding: 0; }

.nav a, .nav label {
    display: block;
    padding: .85rem;
    color: #000;
    background-color: #fff;
    //box-shadow: inset 0 -1px #fff
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in; }

.nav a:focus, .nav a:hover, .nav label:focus, .nav label:hover {
    color: #030303;
    background: rgba(255, 255, 255, 0.5); }

.nav label {
    margin-bottom: 0;
    border-bottom: 1px solid #bbb;
    cursor: pointer; }

/**
 * Styling first level lists items
 */

.group-list a, .group-list label {
    padding-left: 2rem;
    background: rgba(128, 128, 128, 0.3) !important;
    border-bottom: 1px solid #bbb; }

.group-list a:focus, .group-list a:hover, .group-list label:focus, .group-list label:hover {
    background: #999; }

/**
 * Hide nested lists
 */

.group-list {
    height: 100%;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .2s ease-in-out;
 }    //background:rgba(128, 128, 128, 0.5)

.nav__list input[type=checkbox]:checked + label + ul {
    /* reset the height when checkbox is checked */
    max-height: 1000px; }

.nav {
    label > span {
        float: right;
        -webkit-transition: -webkit-transform .2s;
        transition: transform .2s; } }

.nav__list input[type=checkbox]:checked + label > span {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

/* MENU ON LARGE SCREEN */

.button-toggle-menu {
    font-size: 28px;
    position: absolute;
    top: $menu-margin - 2px;
    left: $menu-margin;
    transition: all $menu-transition;
    display: none;

    &:after {
        content: "☰";
        display: block;
        text-rendering: optimizeLegibility; } }

.navigation {
    text-align: left;

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none; }

    .navigation-menu {
        display: flex;
        flex-direction : row;
        justify-content: center;

        @media(max-width: $menu-breakpoint) {
            flex-flow: column wrap; } } }

.has-children {
    position: relative; }

.has-children:hover > .sous-menu {
    display: flex !important; }

.sous-menu {
    display: none;
    position: absolute;
    flex-flow: column wrap;
    min-width: 100px;
    max-height: 250px;
    margin-top: 45px;
    margin-left: 13%;
    line-height: 2em;
    background: white;
    -webkit-box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
    -moz-box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
    box-shadow: 2px 3px 8px 2px rgba(0, 0 , 0, 0.5);
    border-radius: 7px;

    @media(max-width: $menu-breakpoint) {
        position: static;
        border: none;
        background: rgba(grey, 0.5);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-radius: 0; }
    &.is-active {
        display: flex!important; } }

.sous-menu a {
    text-align: left;
    color: black;
    @media(max-width: $menu-breakpoint) {
        padding-left: 30px !important; } }

.sous-menu .sous-menu {
    top: 0px;
    left: 100%;
    background: #1e76d6; }

// LAYOUT AND OVERLAY

.site-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s $menu-transition, opacity $menu-transition;
    z-index: 50; }

.site-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    transition: all $menu-transition ease-out;
    backface-visibility: hidden;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    .site-menu-opened & {
        overflow: initial; } }

.site-body {
    height: 100%;
    backface-visibility: hidden;
    overflow: auto;
    overflow: initial /* <-- allow scrollbar to be over divs */; }

// SMALL SCREEN

@media (max-width: $menu-breakpoint) {
    .hide-on-small {
        display: none; }
    .hide-on-large {
        display: block; }
    .topbar {
        position: sticky; }

    .topbar-logo {
        text-align: center;
        margin-left: -20px; }

    .button-toggle-menu {
        display: block;
        text-decoration: none;
        transform: rotate(0deg);
        z-index: 1010; }

    .topbar-container {
        padding-left: 40px; }

    .navigation {
        width: $menu-width;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        background: white;
        transform: translateX(-100%);
        overflow-y: auto;
        box-shadow: 2px 0 2px rgba(black, .2);

        ul {
            border-top: 1px solid #eee; }

        li {
            border-bottom: 1px solid #ddd;
            line-height: normal; }

        li a, li span {
            padding: 15px;
            color: black !important;
            display: block; }

        li.active > a {
            background: $color-blue !important; }

        li.active > .sous-menu > li.active >button-toggle-menu a {
            background: $color-blue !important; } }

    ul.navigation-menu {
        li {
            display: block; }

        a {
            display: block; } }

    ul.navigation-submenu {
        font-size: 13px;
        margin-left: 30px;
        border-left: 1px dotted gray; }

    .site-menu-opened {
        .site-container {
            transform: translateX($menu-width); }

        .site-overlay {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s; }

        .site-body {
            overflow-y: hidden; }

        .button-toggle-menu {
            transform: rotate(90deg);
            position: absolute;

            &:after {
                content: "✕"; } } } }

// LARGE SCREEN

@media (min-width: $menu-breakpoint) {
    .hide-on-small {
        display: block; }
    .hide-on-large {
        display: none; }
    .navigation {
        position: absolute;
        top: $menu-margin;
        right: $menu-margin;

        a, span {
            display: block;
            padding: 10px 10px;
            user-select: none; }

        a:hover, span:hover {
            color: grey; }

        li.active {

            & > a, & > span {
                font-weight: 900;
                color: $color-blue; }

            & > a:hover, & > span:hover {
                font-weight: 900;
                color: grey; } }

        .sous-menu {
            padding: 15px 10px;
            a {
                padding: 2px 10px;
                font-size: 0.7rem; } }

        .navigation-button {
            border: 2px solid white;
            border-radius: 5px;
            margin-top: -2px;
            margin-left: 10px;
            padding-left: 6px;
            padding-right: 6px; } }

    ul.navigation-menu {
        display: inline-block;

        li {
            float: left;
            position: relative;

            &:hover ul.navigation-submenu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition-delay: 0s; } } }

    .navigation-has-submenu > a:after {
        content: "▼";
        display: inline-block;
        color: white;
        font-size: 5px;
        margin-left: 10px;
        opacity: .5; }

    ul.navigation-submenu {
        width: 150px;
        position: absolute;
        left: 0;
        background: #30373B;
        box-shadow: 0 4px 2px rgba(black, .2);
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
        transition: visibility 0s .10s, opacity .10s, transform .10s;

        li {
            float: none; } } }
