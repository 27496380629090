.blog-snippet {
    padding-bottom: 30px;
    margin-bottom: 30px; }

.blog-post,
.blog-snippet {
    h1 {
        font-size: 38px;

        a {
            color: inherit; } } }

.blog-post {
    margin-top: -15px;

    h1 {
        font-size: 42px; } }

.blog-meta {
    i {
        color: $color-blue;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 20px;

        &:first-child {
            margin-left: 0; } }

    a {
        color: inherit; } }

.blog-link-more {
    margin-top: 20px;
    display: block; }
