.message {
    border: 2px solid $color-gray;
    padding: 20px 30px;
    text-align: center;
    margin-bottom: 35px;
    border-radius: 5px;
    font-weight: 400;

    i {
        color: $color-gray;
        font-size: 30px;
        margin-bottom: 10px; } }

.message-info {
    background: lighten($color-blue, 49);
    border-color: $color-blue;
    color: $color-blue !important;

    i {
        color: $color-blue; } }

.message-success {
    background: lighten($color-green, 49);
    border-color: $color-green;
    color: black;

    i {
        color: $color-green; } }

.message-error {
    background: lighten($color-red, 51.5);
    border-color: $color-red;
    color: $color-red;

    i {
        color: $color-red; } }
