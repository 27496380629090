.social {
    margin-top: 40px;
    text-align: center;

    a {
        display: inline-block;
        margin: 20px 10px 0;
        opacity: .5;
        transition: .25s;

        &:hover {
            opacity: 1;
            transform: scale(1.2); } }

    [class^="icon-"] {
        width: 28px;
        height: 28px;

        path {
            fill: white;
            transition: .25s; }

        &:hover {
            path {
                fill: lighten($color-yellow, 10); } } } }

.social-short {
    text-align: left;
    margin: 0;
    font-weight: normal;

    p, a {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        opacity: 1; }

    [class^="icon-"] {
        path {
            fill: $color-blue; } } }
